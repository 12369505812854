/* eslint-disable no-mixed-spaces-and-tabs */
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { AgenciesNFCCView, Services } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button } from "@twilio-paste/core/button";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { AlertDialog } from "@twilio-paste/core/alert-dialog";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Stack } from "@twilio-paste/core/stack";
import { Scroller } from "../Scroller/Scroller";
import { Input } from "@twilio-paste/core/input";
import { SearchIcon } from "@twilio-paste/icons/esm/SearchIcon";

export const AgenciesTable: FC = () => {
	const [agencies, setAgencies] = useState<AgenciesNFCCView>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [services, setServices] = useState<Services>();
	const [filteredAgencies, setFilteredAgencies] = useState<AgenciesNFCCView>();
	const [agenciesFilterValue, setAgenciesFilterValue] = useState<string>("");
	const [servicesFilterValue, setServicesFilterValue] =
    useState<string>("All Services");
	const [phoneStatusFilterValue, setPhoneStatusFilterValue] =
    useState<string>("All Statuses");
	const [onlineStatusFilterValue, setOnlineStatusFilterValue] =
    useState<string>("All Statuses");
	const { listAgenciesNFCCView, deleteAgency, listServices } = useNFCCContext();
	const { toaster } = useToasterContext();
	const navigate = useNavigate();
	const agencyIdRef = useRef<HTMLInputElement>(null);
	// confirmation modal state management
	const [toDelete, setToDelete] = useState<any>();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = (agencyId: number) => {
		setToDelete({ agencyId });
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const getDate = () => {
		const today = DateTime.local();
		const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
		return todayDate;
	};

	const exportTableData = () => {
		if (filteredAgencies) {
			const csvData = [
				["AgencyID", "Name", "Services", "Service Status", "Last Updated"],
				...filteredAgencies.map(
					({ netsuiteRef, name, services, isLive, lastModifiedDate }) => [
						netsuiteRef,
						name?.replaceAll(",", " "),
						services?.replaceAll(",", "; "),
						isLive?.replaceAll(",", "; "),
						lastModifiedDate,
					],
				),
			];
			const csv = csvData.map((row) => row.join(",")).join("\n");
			const csvBlob = new Blob([csv], { type: "text/csv" });
			const csvUrl = URL.createObjectURL(csvBlob);
			const downloadLink = document.createElement("a");
			downloadLink.href = csvUrl;
			downloadLink.download = `agencies-${getDate()}.csv`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	};

	const handleFilterChange = ({
		target,
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (target.name === "service") {
			setServicesFilterValue(target.value);
		} else if (target.name === "phonestatus") {
			setPhoneStatusFilterValue(target.value);
		} else if (target.name === "onlinestatus") {
			setOnlineStatusFilterValue(target.value);
		}
	};

	const setSearchValue = (value: string) => {
		setAgenciesFilterValue(value);
	};

	const applyFilters = () => {
		if (agencies) {
			let filteredAgs: AgenciesNFCCView = agencies;

			// filter by agency ID function if something in the input field
			if (agenciesFilterValue !== "") {
				filteredAgs = agencies.filter(
					(agency) => agency.netsuiteRef === agenciesFilterValue,
				);
			}

			if (servicesFilterValue !== "All Services") {
				filteredAgs = filteredAgs.filter((a) =>
					(a.services ?? "").includes(servicesFilterValue),
				);
			}

			if (phoneStatusFilterValue !== "All Statuses") {
				filteredAgs = filteredAgs.filter((a) =>
					(a.isLive ?? "").includes(phoneStatusFilterValue),
				);
			}

			if (onlineStatusFilterValue !== "All Statuses") {
				filteredAgs = agencies.filter((a) =>
					(a.isLive ?? "").includes("Online: " + onlineStatusFilterValue),
				);
			}
			setFilteredAgencies(filteredAgs);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				let data: AgenciesNFCCView = await listAgenciesNFCCView();

				data = data.sort((a, b) =>
					(a.netsuiteRef ?? "") > (b.netsuiteRef ?? "")
						? 1
						: (b.netsuiteRef ?? "") > (a.netsuiteRef ?? "")
							? -1
							: 0,
				);

				setAgencies(data);
				setFilteredAgencies(data);
				const s: Services = await listServices();

				setServices(s);

				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve agency data",
					variant: "error",
					dismissAfter: 4000,
				});
			}
		})();
	}, []);

	const handleDelete = async (agencyId: number) => {
		if (agencies) {
			try {
				await deleteAgency(agencyId);
				toaster.push({
					message: "Agency deactivated successfully",
					variant: "success",
					dismissAfter: 4000,
				});
				setAgencies(agencies.filter((agency) => agency.agencyId !== agencyId));
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Agency could not be deactivated",
					variant: "error",
					dismissAfter: 4000,
				});
			}
		}
	};

	const handleCloseWithDelete = () => {
		setIsOpen(false);
		handleDelete(toDelete.agencyId);
		setToDelete({});
	};

	const clearFilters = () => {
		setAgenciesFilterValue("");
		if (agencyIdRef.current) {
			agencyIdRef.current.value = "";
		}
		setServicesFilterValue("All Services");
		setPhoneStatusFilterValue("All Statuses");
		setOnlineStatusFilterValue("All Statuses");
		setFilteredAgencies(agencies);
	};
	return (
		<Box width="100%">
			<Scroller />
			<HeaderTitleText titleText="Agencies" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Button variant="secondary" onClick={exportTableData}>
          Download CSV
					<DownloadIcon decorative={false} title="download csv icon" />
				</Button>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				marginY="space60"
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<Box
					display="flex"
					flexDirection="row"
					columnGap="space60"
					alignItems="flex-end"
				>
					{/* filter by agency ID piece, new input in UI to filter to specific agency */}
					<Box
						display="flex"
						flexDirection="column"
						width="15vw"
						as="form"
						onSubmit={(event: React.SyntheticEvent) => {
							event.preventDefault();
							applyFilters();
						}}
					>
						<Label htmlFor="agencyID">Agency ID</Label>
						<Input
							id="agencyID"
							name="agencyID"
							onBlur={(e) => {
								setSearchValue(e.target.value);
							}}
							type="text"
							ref={agencyIdRef}
							placeholder="agency id"
							defaultValue={agenciesFilterValue || ""}
							insertAfter={
								<Button variant="link" onClick={applyFilters}>
									<SearchIcon decorative={false} title="Filter by agency" />
								</Button>
							}
						/>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw">
						<Label htmlFor="service">Services</Label>
						<Select
							id="service"
							name="service"
							onChange={handleFilterChange}
							value={servicesFilterValue}
						>
							<Option value="All Services">All Services</Option>
							{services && loaded
								? services &&
                  services.map((s: any, index: number) => (
                  	<Option key={index} value={s.name}>
                  		{s.name}
                  	</Option>
                  ))
								: null!}
						</Select>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw">
						<Label htmlFor="phonestatus">Phone Connection Status</Label>
						<Select
							id="phonestatus"
							name="phonestatus"
							onChange={handleFilterChange}
							value={phoneStatusFilterValue}
						>
							<Option value="All Statuses">All Statuses</Option>
							<Option value="Live">Live</Option>
							<Option value="Offline">Offline</Option>
						</Select>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw">
						<Label htmlFor="onlinestatus">Online Connection Status</Label>
						<Select
							id="onlinestatus"
							name="onlinestatus"
							onChange={handleFilterChange}
							value={onlineStatusFilterValue}
						>
							<Option value="All Statuses">All Statuses</Option>
							<Option value="Live">Live</Option>
							<Option value="Offline">Offline</Option>
						</Select>
					</Box>
				</Box>

				<Box
					display="flex"
					justifyContent="flex-end"
					height="fit-content"
					columnGap="space60"
				>
					<Button variant="link" onClick={clearFilters}>
            Clear
					</Button>

					<Button variant="primary" onClick={applyFilters}>
						<FilterIcon decorative={false} title="Apply filters" />
            Apply
					</Button>
				</Box>
			</Box>

			<Table aria-label="Agencies table" striped>
				<THead>
					<Tr>
						<Th>Actions</Th>
						<Th>AgencyID</Th>
						<Th>ID</Th>
						<Th>Name</Th>
						<Th>Services</Th>
						<Th>Service Status</Th>
						<Th>Last Updated</Th>
					</Tr>
				</THead>
				<TBody>
					{filteredAgencies && loaded ? (
						filteredAgencies &&
            filteredAgencies.map((agency: any, rowIndex: number) => (
            	<Tr key={rowIndex}>
            		<Td>
            			<Box
            				display="flex"
            				justifyContent="flex-start"
            				flexDirection="row"
            				columnGap="space60"
            			>
            				<Stack orientation="vertical" spacing="space60">
            					<Button
            						variant="primary"
            						onClick={() => navigate(`/agencies/${agency.agencyId}`)}
            					>
            						<ShowIcon
            							decorative={false}
            							title="View System as Agency"
            						/>
            					</Button>
            					<Button
            						variant="destructive"
            						onClick={() => handleOpen(agency.agencyId)}
            					>
            						<DeleteIcon
            							decorative={false}
            							title="Deactivate Agency"
            						/>
            					</Button>
            				</Stack>
            			</Box>
            		</Td>
            		<Td>{agency.netsuiteRef}</Td>
            		<Td>{agency.agencyId}</Td>
            		<Td>{agency.name}</Td>
            		<Td>{agency.services}</Td>
            		<Td>{agency.isLive}</Td>
            		<Td>{agency.lastModifiedDate}</Td>
            	</Tr>
            ))
					) : (
						<TableSkeletonLoader numberOfTd={7} numberOfTr={4} />
					)}
				</TBody>
			</Table>
			<AlertDialog
				heading="Delete Agency"
				isOpen={isOpen}
				destructive
				onConfirm={handleCloseWithDelete}
				onConfirmLabel="Delete"
				onDismiss={handleClose}
				onDismissLabel="Cancel"
			>
        Are you sure you want to delete this agency?
			</AlertDialog>
		</Box>
	);
}