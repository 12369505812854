/* eslint @typescript-eslint/no-var-requires: "off" */

import { ChangeEvent, CSSProperties, FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Heading } from "@twilio-paste/core/heading";
import { Select, Option } from "@twilio-paste/core/select";
import { UnorderedList, ListItem } from "@twilio-paste/core/list";
import { Stack } from "@twilio-paste/core/stack";
import { Label } from "@twilio-paste/core/label";
import { Alert } from "@twilio-paste/core/alert";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Button } from "@twilio-paste/core/button";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Text } from "@twilio-paste/core/text";
import { Input } from "@twilio-paste/core/input";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
} from "@twilio-paste/core/modal";
import { RadioGroup, Radio } from "@twilio-paste/core/radio-group";
import { useUID, useUIDSeed } from "@twilio-paste/core/dist/uid-library";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import {
  AgencyServiceConfigs,
  AgencyServiceConfig,
  Agency,
  Templates,
  AgencyVideo,
  Service,
} from "@ciptex/nfcc";
import {
  useComboboxPrimitive,
  useMultiSelectPrimitive,
} from "@twilio-paste/core/combobox-primitive";
import { TextArea } from "@twilio-paste/core/textarea";
import { Checkbox } from "@twilio-paste/core/checkbox";
import { TimePicker } from "@twilio-paste/core/time-picker";
import { Separator } from "@twilio-paste/core/separator";
import {
  useFormPillState,
  FormPillGroup,
  FormPill,
} from "@twilio-paste/core/form-pill-group";
import {
  ComboboxListbox,
  ComboboxListboxGroup,
  ComboboxListboxOption,
} from "@twilio-paste/combobox";
import { useAppState } from "../../hooks/useAppState/useAppState";

import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { HelpText } from "@twilio-paste/core/help-text";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { Scroller } from "../Scroller/Scroller";
import { Role, standardTimezones, statesList } from "../../constants";
import { CopyIcon } from "@twilio-paste/icons/esm/CopyIcon";

import { DateTime } from "luxon";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";

type LocationProps = {
  state: {
    selectedServiceIds: number[];
    channelConfigs: any[];
    channelConfigInstances: any[];
  };
};

export const ManageServicesPage: FC = () => {
  const location = useLocation() as unknown as LocationProps;
  const navigate = useNavigate();

  const [deleteIndex, setDeleteIndex] = useState<number>();
  const [deleteIsOpen, setdeleteIsOpen] = useState(false);
  const handleDeleteOpen = (index: number) => {
    setDeleteIndex(index);
    setdeleteIsOpen(true);
  };
  const handleDeleteClose = () => setdeleteIsOpen(false);
  const deleteModalHeadingID = useUID();
  const [editingId, setEditingId] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>();
  // const [reload, setReload] = useState<boolean>(false);
  const { toaster } = useToasterContext();
  const [services, setServices] = useState<any[]>();
  const [channelConfigInstances, setChannelConfigInstances] = useState<any[]>();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  // Copy service bits
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const handleCopyOpen = () => setIsOpenCopyModal(true);
  const handleCopyClose = () => setIsOpenCopyModal(false);
  const [copyId, setCopyId] = useState<number>(0);
  const [copySelection, setCopySelection] = useState<any[]>();
  //

  const [editingChannelConfig, setEditingChannelConfig] = useState<any>();
  const [channelConfigs, setChannelConfigs] = useState<any[]>();
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>();
  const [emailTemplates, setEmailTemplates] = useState<Templates>();
  const [agency, setAgency] = useState<Agency>();

  // filters ***
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>();
  const [servicesFilter, setServicesFilter] = useState<string[]>();
  const [connectionTypesFilter, setConnectionTypesFilter] = useState<string[]>([
    "Phone",
    "Online",
    "Video",
  ]);
  const [languagesFilter, setLanguagesFilter] = useState<string[]>([
    "English",
    "Spanish",
  ]);

  const [categoriesFilterValue, setCategoriesFilterValue] =
    useState<string>("All Categories");
  const [servicesFilterValue, setServicesFilterValue] =
    useState<string>("All Services");
  const [connectionTypesFilterValue, setConnectionTypesFilterValue] =
    useState<string>("All Connection Types");
  const [languagesFilterValue, setLanguagesFilterValue] =
    useState<string>("All Languages");

  const [filteredChannelConfigInstances, setFilteredChannelConfigInstances] =
    useState<any[]>();
  //* **

  const [phoneSuspended, setPhoneSuspended] = useState<boolean>();
  const [onlineSuspended, setOnlineSuspended] = useState<boolean>();
  const [videoSuspended, setVideoSuspended] = useState<boolean>();
  const [agencyVideoEnabled, setAgencyVideoEnabled] = useState<boolean>();

  // Validation***
  const [validationErrorsStartTime, setValidationErrorsStartTime] = useState<
    string[]
  >(["", "", "", "", "", "", ""]);
  const [validationErrorsEndTime, setValidationErrorsEndTime] = useState<
    string[]
  >(["", "", "", "", "", "", ""]);
  const [validationErrorsLimit, setValidationErrorsLimit] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [phoneError, setPhoneError] = useState<string>("");
  const [pageError, setPageError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [destinationUrlError, setDestinationUrlError] = useState<string>("");
  const [agencyIntroError, setAgencyIntroError] = useState<string>("");
  const [logoError, setLogoError] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [statesError, setStatesError] = useState<string>("");
  const [calendlyError, setCalendlyError] = useState<string>("");
  const [calendlyTokenError, setCalendlyTokenError] = useState<string>("");
  const [connectionTypeError, setConnectionTypeError] = useState<string>("");

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpen = () => setIsOpenModal(true);
  const handleClose = () => setIsOpenModal(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const states = statesList;
  const seed = useUIDSeed();
  const [filteredItems, setFilteredItems] = useState([...states]);
  const { appState } = useAppState();
  const { agencyId, success } = useParams();
  const pathname = "/agencies/" + agencyId;
  const formPillState = useFormPillState();

  const {
    getAgencyAvailableServices,
    listStates,
    listTimezones,
    listAgencyAgencyServiceConfig,
    getService,
    updateAgencyServiceConfig,
    createAgencyServiceConfig,
    deleteAgencyServiceConfig,
    getAgency,
    updateAgency,
    listTemplates,
    listServices,
    getAgencyVideo,
  } = useNFCCContext();

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultiSelectPrimitive({});

  const handleEditOpen = async (id: number, e: any[] | undefined) => {
    setEditingId(id);

    if (e === undefined) {
      if (channelConfigInstances) {
        const CCIs = channelConfigInstances.map((x) => x);
        const ccss = CCIs.filter((x) => x.index === id)[0];

        // get service
        if (ccss.serviceId != 0) {
          const serv: Service = await getService(ccss.serviceId);

          ccss.availableConnectionTypes = serv.availableConnectionTypes;
          setEditingChannelConfig(ccss);
        } else {
          ccss.availableConnectionTypes = [];
          setEditingChannelConfig(ccss);
        }

        const s = states.filter((x) => !ccss.states.includes(x));
        setFilteredItems(s);
      }
    } else {
      const CCIs = e.map((x) => x);
      const ccss = CCIs.filter((x) => x.index === id)[0];

      // get service
      if (ccss.serviceId != 0) {
        const serv: Service = await getService(ccss.serviceId);

        ccss.availableConnectionTypes = serv.availableConnectionTypes;
        setEditingChannelConfig(ccss);
      } else {
        ccss.availableConnectionTypes = [];
        setEditingChannelConfig(ccss);
      }

      if (ccss.states) {
        const s = states.filter((x) => !ccss.states.includes(x));
        setFilteredItems(s);
      } else {
        setFilteredItems(states);
      }
    }

    setIsOpenEditModal(true);
  };

  const handleEditCancel = () => {
    if (channelConfigInstances) {
      const CCIs = channelConfigInstances.map((x) => x);
      if (CCIs.length === 1 && CCIs[0].index < 0) {
        setLoaded(false);
        setChannelConfigInstances(CCIs.filter((x) => x.index !== editingId));
      }
    }
    setEditingId(0);
    setIsOpenEditModal(false);
    setPhoneError("");
    setEmailError("");
    setLogoError("");
    setAgencyIntroError("");
    setValidationErrorsLimit(["", "", "", "", "", "", ""]);
    setValidationErrorsStartTime(["", "", "", "", "", "", ""]);
    setValidationErrorsEndTime(["", "", "", "", "", "", ""]);
    setPageError(false);
    setDuplicateError(false);
  };

  const duplicateCheck = (cci: any) => {
    // duplicate check
    const service = cci.serviceName;
    const { language } = cci;
    const { connectionType } = cci;
    if (channelConfigInstances && service && language && connectionType) {
      const ccis = channelConfigInstances
        .filter((x) => x.index !== editingId)
        .filter((x) => x.language === language)
        .filter((x) => x.connectionType === connectionType)
        .filter((x) => x.serviceName === service);

      if (ccis.length > 0) {
        setDuplicateError(true);
      } else {
        setDuplicateError(false);
      }
    }
  };

  const handleSelectItemOnClick = (e: any) => {
    addSelectedItem(e);

    setFilteredItems((currentFilteredItems) =>
      currentFilteredItems.filter((item) => item !== e)
    );

    if (editingChannelConfig) {
      const newStates = editingChannelConfig.states.map((x: string) => x);
      newStates.push(e);
      const newCCI = { ...editingChannelConfig, states: newStates };
      setEditingChannelConfig(newCCI);
    }
  };

  const handleRemoveItemOnClick = (e: any) => {
    removeSelectedItem(e);

    setFilteredItems((currentFilteredItems) =>
      [...currentFilteredItems, e].sort()
    );

    if (editingChannelConfig) {
      const newStates: string[] = editingChannelConfig.states.filter(
        (s: string) => s !== e
      );
      const newCCI = { ...editingChannelConfig, states: newStates };
      setEditingChannelConfig(newCCI);
    }
  };

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem,
    selectItem,
  } = useComboboxPrimitive({
    items: filteredItems,
    initialInputValue: "",
    onSelectedItemChange: ({ selectedItem: selected }) => {
      if (selected != null) {
        handleSelectItemOnClick(selected);
      }

      selectItem(null!);
    },
  });

  const inputId = seed("input-element");

  useEffect(() => {
    (async () => {
      try {
        const agId: number = parseInt(agencyId ?? "0") || appState.agencyId;

        const ag = await getAgency(agId);
        setAgency(ag);

        if (ag.isLive) {
          setPhoneSuspended(false);
        } else {
          setPhoneSuspended(true);
        }

        if (ag.isLiveOnline) {
          setOnlineSuspended(false);
        } else {
          setOnlineSuspended(true);
        }

        if (ag.isLiveVideo) {
          setVideoSuspended(false);
        } else {
          setVideoSuspended(true);
        }

        const agencyServiceConfigs: AgencyServiceConfigs =
          await listAgencyAgencyServiceConfig(
            parseInt(agencyId ?? "0") || appState.agencyId
          );

        // Toast for when no services have been setup
        if ((agencyServiceConfigs as any).error_message) {
          console.error("no data");
          toaster.push({
            message: "Currently there are no services setup for this agency",
            variant: "neutral",
            dismissAfter: 4000,
          });
        }

        if (!(agencyServiceConfigs as any).error_message) {
          const newChConfigInstances: any[] = [];
          const days: string[] = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];

          const servs = await listServices();

          const promises = agencyServiceConfigs.map(async (asc) => {
            const startTimes: string[] = [];
            const endTimes: string[] = [];
            const offline: boolean[] = [];
            const override: boolean[] = [];
            const config: any[] = [];
            const limits = (asc.dailyConnectionLimits ?? []).map(
              ({ dailyConnectionLimit }) => dailyConnectionLimit ?? -1
            );
            const offset = asc.timezone?.utcOffset;

            if (asc.dailyConnectionLimits) {
              for (const d of asc.dailyConnectionLimits) {
                if (d.startTime && d.endTime) {
                  const c = DateTime.now().toString().split("T")[0];
                  const dtStart = DateTime.utc(
                    parseInt(c.split("-")[0]),
                    parseInt(c.split("-")[1]),
                    parseInt(c.split("-")[2]),
                    parseInt(d.startTime.split(":")[0]),
                    parseInt(d.startTime.split(":")[1], 0)
                  );
                  const rezonedStart = dtStart.setZone("UTC" + offset);

                  const dtEnd = DateTime.utc(
                    parseInt(c.split("-")[0]),
                    parseInt(c.split("-")[1]),
                    parseInt(c.split("-")[2]),
                    parseInt(d.endTime.split(":")[0]),
                    parseInt(d.endTime.split(":")[1], 0)
                  );
                  const rezonedEnd = dtEnd.setZone("UTC" + offset);

                  const startTime: string = rezonedStart
                    .toString()
                    .split("T")[1]
                    .replace("Z", "")
                    .split(".")[0];
                  const endTime: string = rezonedEnd
                    .toString()
                    .split("T")[1]
                    .replace("Z", "")
                    .split(".")[0];

                  if (startTime === "00:00:00" && endTime === "23:59:00") {
                    override.push(true);
                  } else {
                    override.push(false);
                  }

                  if (startTime === "00:00:00" && endTime === "00:00:00") {
                    offline.push(true);
                  } else {
                    offline.push(false);
                  }

                  config.push({
                    weekday: d.weekday,
                    startTime: startTime.split(".")[0],
                    endTime: endTime.split(".")[0],
                    limit: d.dailyConnectionLimit,
                  });
                  startTimes.push(startTime.split(".")[0]);
                  endTimes.push(endTime.split(".")[0]);
                }
              }
            }

            const s = servs?.filter(
              (x) => x.serviceId === (asc.service ?? {}).serviceId
            )[0];

            const serv = await getService((asc.service ?? {}).serviceId ?? 0);

            const newCCI = {
              index: asc.agencyServiceConfigId,
              serviceId: (asc.service ?? {}).serviceId,
              serviceName: s.name,
              category: s.category,
              displayName:
                s.name + " - " + asc.connectionType + " - " + asc.language,
              connectionType: asc.connectionType,
              language: asc.language,
              startTimes: startTimes,
              endTimes: endTimes,
              offline: offline,
              commsLogoUrl: asc.commsLogoUrl,
              destination: asc.destination,
              destinationEmail: asc.destinationEmail,
              commsUrl: asc.commsUrl,
              destinationIntakeUrl:
                (asc.destinationType == "URL" && asc.destination) || "",
              commsIntroduction: asc.commsIntroduction,
              connectionLimits: limits,
              timezone: (asc.timezone || {}).name,
              states: (asc.states ?? []).map(({ nameLong }) => nameLong ?? -1),
              timeOverride: override,
              commsPhone: asc.commsPhone,
              dailyConfig: config,
              raceSchedulesUUID: asc.raceSchedulesUUID,
              calendlyUrl: (asc as any).calendlyUrl,
              showBooking: (serv as any).enableBooking,
              calendlyToken: asc.calendlyToken,
            };
            newChConfigInstances.push(newCCI);
          });
          const finalAnswer = await Promise.all(promises);
          /*
										for (const a in agencyServiceConfigs) {
											console.log(a,performance.now()-start)

											const asc = { ...agencyServiceConfigs[a] };


											const startTimes: string[] = [];
											const endTimes: string[] = [];
											const offline: boolean[] = [];
											const override: boolean[] = [];
											const config: any[] = [];
											const limits = (asc.dailyConnectionLimits ?? []).map(
												({ dailyConnectionLimit }) => dailyConnectionLimit ?? -1
											);
											const offset = asc.timezone?.utcOffset;

											if (asc.dailyConnectionLimits) {
												console.log(a,'limits',performance.now()-start)
												for (const d of asc.dailyConnectionLimits) {

													if (d.startTime && d.endTime) {
														const c = DateTime.now().toString().split("T")[0];
														const dtStart = DateTime.utc(
															parseInt(c.split("-")[0]),
															parseInt(c.split("-")[1]),
															parseInt(c.split("-")[2]),
															parseInt(d.startTime.split(":")[0]),
															parseInt(d.startTime.split(":")[1], 0)
														);
														const rezonedStart = dtStart.setZone("UTC" + offset);

														const dtEnd = DateTime.utc(
															parseInt(c.split("-")[0]),
															parseInt(c.split("-")[1]),
															parseInt(c.split("-")[2]),
															parseInt(d.endTime.split(":")[0]),
															parseInt(d.endTime.split(":")[1], 0)
														);
														const rezonedEnd = dtEnd.setZone("UTC" + offset);

														const startTime: string = rezonedStart
															.toString()
															.split("T")[1]
															.replace("Z", "")
															.split(".")[0];
														const endTime: string = rezonedEnd
															.toString()
															.split("T")[1]
															.replace("Z", "")
															.split(".")[0];

														if (startTime === "00:00:00" && endTime === "23:59:00") {
															override.push(true);
														} else {
															override.push(false);
														}

														if (startTime === "00:00:00" && endTime === "00:00:00") {
															offline.push(true);
														} else {
															offline.push(false);
														}

														config.push({
															weekday: d.weekday,
															startTime: startTime.split(".")[0],
															endTime: endTime.split(".")[0],
															limit: d.dailyConnectionLimit
														});
														startTimes.push(startTime.split(".")[0]);
														endTimes.push(endTime.split(".")[0]);

													}
													console.log(a,'limits-e',performance.now()-start)
												}
											}


											const s = servs?.filter(
												(x) => x.serviceId === (asc.service ?? {}).serviceId ?? 0
											)[0];

											const serv = await getService((asc.service ?? {}).serviceId ?? 0)

											console.log(a,'get serv',performance.now()-start)


											const newCCI = {
												index: asc.agencyServiceConfigId,
												serviceId: (asc.service ?? {}).serviceId,
												serviceName: s.name,
												category: s.category,
												displayName:
													s.name + " - " + asc.connectionType + " - " + asc.language,
												connectionType: asc.connectionType,
												language: asc.language,
												startTimes: startTimes,
												endTimes: endTimes,
												offline: offline,
												commsLogoUrl: asc.commsLogoUrl,
												destination: asc.destination,
												destinationEmail: asc.destinationEmail,
												commsUrl: asc.commsUrl,
												destinationIntakeUrl:
													(asc.destinationType == "URL" && asc.destination) || "",
												commsIntroduction: asc.commsIntroduction,
												connectionLimits: limits,
												timezone: (asc.timezone || {}).name,
												states: (asc.states ?? []).map(({ nameLong }) => nameLong ?? -1),
												timeOverride: override,
												commsPhone: asc.commsPhone,
												dailyConfig: config,
												raceSchedulesUUID: asc.raceSchedulesUUID,
												calendlyUrl: (asc as any).calendlyUrl,
												showBooking: (serv as any).enableBooking
											};
											newChConfigInstances.push(newCCI);
											console.log(a,'e',performance.now()-start)
										}
					*/
          if (newChConfigInstances) {
            setChannelConfigInstances(newChConfigInstances.map((x) => x));
            setFilteredChannelConfigInstances(
              newChConfigInstances.map((x) => x)
            );

            const s = await getAgencyAvailableServices(agId);
            setServices(s);

            // set up filters
            setCategoriesFilter([
              ...new Set(
                newChConfigInstances.map(({ category }) => category ?? -1)
              ),
            ]);
            setServicesFilter([
              ...new Set(
                newChConfigInstances.map(({ serviceName }) => serviceName ?? -1)
              ),
            ]);
          }
          setLoaded(true);

          const agencyVideo: AgencyVideo = await getAgencyVideo(agId);
          if (agencyVideo.status === "Active") {
            setAgencyVideoEnabled(true);
          } else {
            setAgencyVideoEnabled(false);
          }

          const t = await listTemplates();
          setEmailTemplates(t);
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve configuration data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const formatTime = (time: string) => {
    if (time) {
      let hours: string = time.split(":")[0];
      let minutes = time.split(":")[1];
      let suffix = "AM";
      if (parseInt(hours) > 12) {
        hours = (parseInt(hours) - 12).toString();
        suffix = "PM";
      }
      if (parseInt(hours) === 12) {
        suffix = "PM";
      }
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      if (hours === "00") {
        hours = "12";
      }
      return hours + ":" + minutes + " " + suffix;
    }
  };

  const handleCopyService = () => {
    if (channelConfigInstances) {
      const dd = [];
      for (const c of channelConfigInstances) {
        dd.push({ id: c.index, label: c.displayName });
      }
      console.log(dd);
      setCopySelection(dd);
      handleCopyOpen();
    }
  };

  const handleCopyContinue = () => {
    console.log("handleCopyContinue!");
    const index = copyId;
    console.log(index);
    if (channelConfigInstances) {
      const newIndex = -Math.floor(Math.random() * 1000 + 1);
      const CCIs: any[] = channelConfigInstances.map((x) => x);
      const ccss = CCIs.filter((x) => x.index == index)[0];
      const newCCI: any = { ...ccss, index: newIndex };
      CCIs.push(newCCI);

      console.log("new cci", newCCI);
      console.log("ccis", CCIs);

      setChannelConfigInstances(CCIs);
      handleCopyClose();
      handleEditOpen(newCCI.index, CCIs);
    }
  };

  const handleCopySelection = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setCopyId(parseInt(target.value));
  };

  const handleAddNewService = () => {
    if (channelConfigInstances) {
      const newIndex = -Math.floor(Math.random() * 1000 + 1);
      const CCIs: any[] = channelConfigInstances.map((x) => x);
      const newCCI: any = {
        index: newIndex,
        serviceId: 0,
        serviceName: "",
        category: "",
        displayName: "",
        connectionType: "",
        language: "",
        startTimes: [
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
        ],
        endTimes: [
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
        ],
        offline: [false, false, false, false, false, false, false],
        commsLogoUrl: "",
        destination: "",
        commsUrl: "",
        destinationIntakeUrl: "",
        commsIntroduction: "",
        connectionLimits: [0, 0, 0, 0, 0, 0, 0],
        timezone: "EST",
        states: [],
        timeOverride: [false, false, false, false, false, false, false],
        commsPhone: "",
        calendlyUrl: "",
        availableConnectionTypes: [],
      };
      const config = [];
      const days: string[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      for (const i in days) {
        const c = {
          weekday: days[parseInt(i)],
          startTime: newCCI.startTimes[parseInt(i)],
          endTime: newCCI.endTimes[parseInt(i)],
          limit: newCCI.connectionLimits[parseInt(i)],
        };
        config.push(c);
      }

      CCIs.push({ ...newCCI, dailyConfig: config });
      setChannelConfigInstances(CCIs);
      handleEditOpen(newCCI.index, CCIs);
    } else {
      setLoaded(true);
      const newIndex = -Math.floor(Math.random() * 1000 + 1);
      const CCIs: any[] = [];
      const newCCI: any = {
        index: newIndex,
        serviceId: 0,
        serviceName: "",
        category: "",
        displayName: "",
        connectionType: "",
        language: "",
        startTimes: [
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
        ],
        endTimes: [
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
          "--:--:--",
        ],
        offline: [false, false, false, false, false, false, false],
        commsLogoUrl: "",
        destination: "",
        commsUrl: "",
        destinationIntakeUrl: "",
        commsIntroduction: "",
        connectionLimits: [0, 0, 0, 0, 0, 0, 0],
        timezone: "EST",
        states: [],
        timeOverride: [false, false, false, false, false, false, false],
        commsPhone: "",
        calendlyUrl: "",
      };
      const config = [];
      const days: string[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      for (const i in days) {
        const c = {
          weekday: days[parseInt(i)],
          startTime: newCCI.startTimes[parseInt(i)],
          endTime: newCCI.endTimes[parseInt(i)],
          limit: newCCI.connectionLimits[parseInt(i)],
        };
        config.push(c);
      }

      CCIs.push({ ...newCCI, dailyConfig: config });
      setChannelConfigInstances(CCIs);

      handleEditOpen(newCCI.index, CCIs);
    }
  };

  const handleTimeChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (channelConfigInstances) {
      const index: number = parseInt(target.name.split("#")[2]);
      const weekday: string = target.name.split("#")[0];
      const fieldName: string = target.name.split("#")[1];

      if (fieldName === "startTimes") {
        const v = validationErrorsStartTime.map((x) => x);
        v.splice(index, 1, "");
        setValidationErrorsStartTime(v);

        const t: boolean[] = editingChannelConfig.timeOverride;
        t.splice(index, 1, false);
        const times = editingChannelConfig.startTimes;
        times.splice(index, 1, target.value);
        const newCCI = {
          ...editingChannelConfig,
          ["timeOverride"]: t,
          [fieldName]: times,
        };
        setEditingChannelConfig(newCCI);

        // validate
        const endTime = newCCI.endTimes[index];
        const startDateTime = new Date();
        startDateTime.setHours(parseInt(target.value.split(":")[0]));
        startDateTime.setMinutes(parseInt(target.value.split(":")[1]));

        if (endTime !== "--:--" && endTime !== "--:--:--") {
          const endDateTime = new Date();
          endDateTime.setHours(parseInt(endTime.split(":")[0]));
          endDateTime.setMinutes(parseInt(endTime.split(":")[1]));

          if (startDateTime > endDateTime) {
            const v = validationErrorsStartTime.map((x) => x);
            v.splice(index, 1, "Start time cannot be after end time");
            setValidationErrorsStartTime(v);
          } else {
            const v = validationErrorsStartTime.map((x) => x);
            v.splice(index, 1, "");
            setValidationErrorsStartTime(v);
          }
        }
      } else if (fieldName === "endTimes") {
        const v = validationErrorsEndTime.map((x) => x);
        v.splice(index, 1, "");
        setValidationErrorsEndTime(v);

        const t: boolean[] = editingChannelConfig.timeOverride;
        t.splice(index, 1, false);
        const times = editingChannelConfig.endTimes;
        times.splice(index, 1, target.value);
        const newCCI = {
          ...editingChannelConfig,
          ["timeOverride"]: t,
          [fieldName]: times,
        };
        setEditingChannelConfig(newCCI);

        // validate
        const startTime = newCCI.startTimes[index];
        const endDateTime = new Date();
        endDateTime.setHours(parseInt(target.value.split(":")[0]));
        endDateTime.setMinutes(parseInt(target.value.split(":")[1]));

        if (startTime !== "--:--" && startTime !== "--:--:--") {
          const startDateTime = new Date();
          startDateTime.setHours(parseInt(startTime.split(":")[0]));
          startDateTime.setMinutes(parseInt(startTime.split(":")[1]));

          if (startDateTime > endDateTime) {
            const v = validationErrorsEndTime.map((x) => x);
            v.splice(index, 1, "End time cannot be before start time");
            setValidationErrorsEndTime(v);
          } else {
            const v = validationErrorsEndTime.map((x) => x);
            v.splice(index, 1, "");
            setValidationErrorsEndTime(v);
          }
        }
      } else if (fieldName === "connectionlimits") {
        const limits = editingChannelConfig.connectionLimits;
        limits.splice(index, 1, target.value);
        const newCCI = { ...editingChannelConfig, [fieldName]: limits };
        setEditingChannelConfig(newCCI);

        if (
          isNaN(parseInt(target.value)) ||
          parseInt(target.value).toString().length !== target.value.length
        ) {
          const v = validationErrorsLimit.map((x) => x);
          v.splice(index, 1, "Connection limit must be a number");
          setValidationErrorsLimit(v);
        } else if (parseInt(target.value) < 0) {
          const v = validationErrorsLimit.map((x) => x);
          v.splice(index, 1, "Connection limit must be a positive number");
          setValidationErrorsLimit(v);
        } else if (parseInt(target.value) === 0) {
          const v = validationErrorsLimit.map((x) => x);
          v.splice(
            index,
            1,
            "Connection limit is set to 0, this means you will not get any connections on this day"
          );
          setValidationErrorsLimit(v);
        } else {
          const v = validationErrorsLimit.map((x) => x);
          v.splice(index, 1, "");
          setValidationErrorsLimit(v);
        }
      }
    }
  };

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (editingChannelConfig) {
      let newcc: any;
      if (target.name === "serviceId") {
        const serv = services?.filter(
          (x) => x.serviceId === parseInt(target.value)
        )[0];
        if (serv) {
          newcc = {
            ...editingChannelConfig,
            serviceId: target.value,
            serviceName: serv.name,
            category: serv.category,
            showBooking: (serv as any).enableBooking,
            availableConnectionTypes: serv.availableConnectionTypes,
            availableLanguages: serv.availableLanguages,
          };
          setEditingChannelConfig(newcc);
        }
      } else {
        newcc = { ...editingChannelConfig, [target.name]: target.value };
        setEditingChannelConfig(newcc);
      }

      duplicateCheck(newcc);
    }
  };

  const validate = () => {
    // validate
    if (channelConfigInstances) {
      let isError = false;
      const newStartTimeErrors = [];
      const newEndTimeErrors = [];
      for (const x in editingChannelConfig.startTimes) {
        const startTime = editingChannelConfig.startTimes[x];
        const endTime = editingChannelConfig.endTimes[x];
        let timeError = false;

        // check if unset
        if (startTime === "--:--" || startTime === "--:--:--") {
          newStartTimeErrors.push("Start time cannot be blank");
          timeError = true;
          isError = true;
        } else {
          newStartTimeErrors.push("");
        }

        if (endTime === "--:--" || endTime === "--:--:--") {
          newEndTimeErrors.push("End time cannot be blank");
          timeError = true;
          isError = true;
        }

        if (!timeError) {
          const startDt = new Date();

          startDt.setHours(parseInt(startTime.split(":")[0]));
          startDt.setMinutes(parseInt(startTime.split(":")[1]));

          const endDt = new Date();
          endDt.setHours(parseInt(endTime.split(":")[0]));
          endDt.setMinutes(parseInt(endTime.split(":")[1]));

          if (endDt < startDt) {
            newEndTimeErrors.push("End time cannot be before start time");
            isError = true;
          } else {
            newEndTimeErrors.push("");
          }
        }
      }
      setValidationErrorsStartTime(newStartTimeErrors);
      setValidationErrorsEndTime(newEndTimeErrors);

      const limitVal = [];
      for (const l in editingChannelConfig.connectionLimits) {
        if (!editingChannelConfig.offline[l]) {
          if (parseInt(editingChannelConfig.connectionLimits[l]) === 0) {
            limitVal.push(
              "Connection limit is set to 0, this means you will not get any connections on this day"
            );
          } else if (
            isNaN(parseInt(editingChannelConfig.connectionLimits[l])) ||
            parseInt(editingChannelConfig.connectionLimits[l]).toString()
              .length !==
              editingChannelConfig.connectionLimits[l].toString().length
          ) {
            limitVal.push("Connection limit must be a number");
          } else if (parseInt(editingChannelConfig.connectionLimits[l]) < 0) {
            limitVal.push("Connection limit must be a positive number");
          } else {
            limitVal.push("");
          }
        } else {
          limitVal.push("");
        }
      }

      setValidationErrorsLimit(limitVal);

      setPhoneError("");

      const dest = editingChannelConfig.destination
        .replace("+1", "")
        .replaceAll("-", "");

      if (
        editingChannelConfig.connectionType === "Phone" &&
        (dest === undefined || dest === "")
      ) {
        setPhoneError("Phone number cannot be blank");
        isError = true;
      } else if (
        editingChannelConfig.connectionType === "Phone" &&
        !["800", "888", "877", "866", "855", "844", "833"].includes(
          dest.substring(0, 3)
        ) &&
        parseInt(agencyId ?? "0") != 298
      ) {
        setPhoneError("Phone number must be toll-free");
        isError = true;
      } else if (
        editingChannelConfig.connectionType === "Phone" &&
        parseInt(dest).toString().length !==
          dest.length - parseInt(dest.match(/-/g) || [].length)
      ) {
        setPhoneError(
          "Phone number cannot contain text characters (exc. dashes)"
        );
        isError = true;
      } else if (
        editingChannelConfig.connectionType === "Phone" &&
        dest.length != 10
      ) {
        setPhoneError("Phone number must be exactly 10 characters");
        isError = true;
      }

      setEmailError("");
      setLogoError("");
      setAgencyIntroError("");

      if (
        ["Online", "Video"].includes(editingChannelConfig.connectionType) &&
        (editingChannelConfig.destinationEmail === undefined ||
          editingChannelConfig.destinationEmail === "")
      ) {
        setEmailError("Email address cannot be blank");
        isError = true;
      }
      if (
        ["Online", "Video"].includes(editingChannelConfig.connectionType) &&
        (editingChannelConfig.commsIntroduction === undefined ||
          editingChannelConfig.commsIntroduction === "")
      ) {
        setAgencyIntroError("Agency introduction cannot be blank");
        isError = true;
      }
      if (
        ["Online", "Video"].includes(editingChannelConfig.connectionType) &&
        (editingChannelConfig.commsLogoUrl === undefined ||
          editingChannelConfig.commsLogoUrl === "")
      ) {
        setLogoError("Logo cannot be blank");
        isError = true;
      } else if (
        ["Online", "Video"].includes(editingChannelConfig.connectionType) &&
        editingChannelConfig.commsLogoUrl.substring(0, 4) !== "http"
      ) {
        setLogoError("Logo must be a link to an image");
        isError = true;
      }

      if (editingChannelConfig.states.length === 0) {
        setStatesError("At least one state must be selected");
        isError = true;
      } else {
        setStatesError("");
      }

      if (
        editingChannelConfig.connectionType === "Online" &&
        Object.keys(editingChannelConfig).includes("destinationIntakeUrl") &&
        editingChannelConfig.destinationIntakeUrl
      ) {
        if (
          editingChannelConfig.destinationIntakeUrl.length >= 1 &&
          editingChannelConfig.destinationIntakeUrl.substring(0, 4) !== "http"
        ) {
          setDestinationUrlError("Intake url must start with http or https");
          isError = true;
        } else {
          setDestinationUrlError("");
        }
      } else {
        setDestinationUrlError("");
      }

      if (
        ["Online", "Video"].includes(editingChannelConfig.connectionType) &&
        Object.keys(editingChannelConfig).includes("calendlyUrl") &&
        editingChannelConfig.calendlyUrl
      ) {
        if (
          editingChannelConfig.calendlyUrl.length >= 1 &&
          editingChannelConfig.calendlyUrl.substring(0, 21) !==
            "https://calendly.com/"
        ) {
          setCalendlyError("Calendly url must be a Calendly link");
          isError = true;
        } else {
          setCalendlyError("");
        }
      } else {
        setCalendlyError("");
      }

      if (
        ["Video"].includes(editingChannelConfig.connectionType) &&
        Object.keys(editingChannelConfig).includes("calendlyUrl") &&
        editingChannelConfig.calendlyUrl
      ) {
        if (
          editingChannelConfig.calendlyToken?.length == 0 ||
          !Object.keys(editingChannelConfig).includes("calendlyToken")
        ) {
          setCalendlyTokenError(
            "Calendly token is required when using Calendly"
          );
          isError = true;
        } else {
          setCalendlyTokenError("");
        }
      } else {
        setCalendlyTokenError("");
      }

      if (
        !editingChannelConfig.availableConnectionTypes?.includes(
          editingChannelConfig.connectionType
        )
      ) {
        setConnectionTypeError(
          "This connection type is not available for this service, please choose another"
        );
        isError = true;
      } else {
        setConnectionTypeError("");
      }

      return isError;
    }
  };

  const handleConnectionTypeChange = (e: any) => {
    console.log(e);
    if (channelConfigInstances) {
      let newcc = { ...editingChannelConfig, connectionType: e };

      if (e === "Video") {
        console.log("here");
        newcc.destination = "Video";
        console.log(newcc);
        setEditingChannelConfig(newcc);
        duplicateCheck(newcc);
      } else {
        setEditingChannelConfig(newcc);
        duplicateCheck(newcc);
      }
    }
  };

  const handleLanguageChange = (e: any) => {
    if (channelConfigInstances) {
      const newcc = { ...editingChannelConfig, language: e };
      setEditingChannelConfig(newcc);
      duplicateCheck(newcc);
    }
  };

  const handleTextChange = (e: any) => {
    if (channelConfigInstances) {
      const newcc = {
        ...editingChannelConfig,
        commsIntroduction: e.target.value,
      };
      setEditingChannelConfig(newcc);
    }
  };

  const handleEnableDisable24hours = (e: any) => {
    if (channelConfigInstances) {
      const index: number = parseInt(e.target.name.split("#")[2]);
      const weekday: string = e.target.name.split("#")[0];
      if (e.target.checked) {
        const t: boolean[] = editingChannelConfig.timeOverride;
        t.splice(index, 1, true);
        const t1: boolean[] = editingChannelConfig.offline;
        t1.splice(index, 1, false);
        const sTimes = editingChannelConfig.startTimes;
        sTimes.splice(index, 1, "00:00:00");
        const eTimes = editingChannelConfig.endTimes;
        eTimes.splice(index, 1, "23:59:00");
        const newCCI = {
          ...editingChannelConfig,
          ["startTimes"]: sTimes,
          ["endTimes"]: eTimes,
          ["timeOverride"]: t,
          offline: t1,
        };

        setEditingChannelConfig(newCCI);
      } else {
        const t: boolean[] = editingChannelConfig.timeOverride;
        t.splice(index, 1, false);
        const t1: boolean[] = editingChannelConfig.offline;
        t1.splice(index, 1, false);
        const sTimes = editingChannelConfig.startTimes;
        sTimes.splice(index, 1, "--:--:--");
        const eTimes = editingChannelConfig.endTimes;
        eTimes.splice(index, 1, "--:--:--");
        const newCCI = {
          ...editingChannelConfig,
          ["startTimes"]: sTimes,
          ["endTimes"]: eTimes,
          ["timeOverride"]: t,
          offline: t1,
        };
        setEditingChannelConfig(newCCI);
      }
    }
  };

  const handleEnableDisableOffline = (e: any) => {
    if (channelConfigInstances) {
      const index: number = parseInt(e.target.name.split("#")[2]);
      const weekday: string = e.target.name.split("#")[0];

      const v2 = validationErrorsEndTime.map((x) => x);
      v2.splice(index, 1, "");
      setValidationErrorsEndTime(v2);

      const v1 = validationErrorsStartTime.map((x) => x);
      v1.splice(index, 1, "");
      setValidationErrorsStartTime(v1);

      const v3 = validationErrorsLimit.map((x) => x);
      v3.splice(index, 1, "");
      setValidationErrorsLimit(v3);

      if (e.target.checked) {
        const t: boolean[] = editingChannelConfig.timeOverride;
        t.splice(index, 1, false);
        const o: boolean[] = editingChannelConfig.offline;
        o.splice(index, 1, true);
        const c: string[] = editingChannelConfig.connectionLimits;
        c.splice(index, 1, "0");

        const sTimes = editingChannelConfig.startTimes;
        sTimes.splice(index, 1, "00:00:00");
        const eTimes = editingChannelConfig.endTimes;
        eTimes.splice(index, 1, "00:00:00");

        const newCCI = {
          ...editingChannelConfig,
          ["startTimes"]: sTimes,
          ["endTimes"]: eTimes,
          ["timeOverride"]: t,
          ["offline"]: o,
          connectionLimits: c,
        };
        setEditingChannelConfig(newCCI);
      } else {
        const t: boolean[] = editingChannelConfig.timeOverride;
        t.splice(index, 1, false);
        const o: boolean[] = editingChannelConfig.offline;
        o.splice(index, 1, false);
        const sTimes = editingChannelConfig.startTimes;
        sTimes.splice(index, 1, "--:--:--");
        const eTimes = editingChannelConfig.endTimes;
        eTimes.splice(index, 1, "--:--:--");
        const c: string[] = editingChannelConfig.connectionLimits;
        c.splice(index, 1, "0");
        const newCCI = {
          ...editingChannelConfig,
          ["startTimes"]: sTimes,
          ["endTimes"]: eTimes,
          ["timeOverride"]: t,
          ["offline"]: o,
          connectionLimits: c,
        };
        setEditingChannelConfig(newCCI);
      }
    }
  };

  const updateASC = async (asc: AgencyServiceConfig) => {
    if (asc.agencyServiceConfigId) {
      await updateAgencyServiceConfig(asc.agencyServiceConfigId, asc).catch(
        (error: any) => {
          // retry, likely a locking issue
          updateASC(asc);
        }
      );
    }
  };

  const createASC = async (asc: AgencyServiceConfig) => {
    await createAgencyServiceConfig(asc);
  };

  const changeConnectionStatus = async (type: string, enabled: number) => {
    const agId: number = parseInt(agencyId ?? "0") || appState.agencyId;
    const ag: Agency = await getAgency(agId);

    if (type === "phone") {
      await updateAgency(agId, { ...ag, isLive: enabled });
      if (enabled === 1) {
        setPhoneSuspended(false);
      } else {
        setPhoneSuspended(true);
      }
    } else if (type === "online") {
      await updateAgency(agId, { ...ag, isLiveOnline: enabled });
      if (enabled === 1) {
        setOnlineSuspended(false);
      } else {
        setOnlineSuspended(true);
      }
    } else {
      await updateAgency(agId, { ...ag, isLiveVideo: enabled });
      if (enabled === 1) {
        setVideoSuspended(false);
      } else {
        setVideoSuspended(true);
      }
    }
  };

  const applyFilters = (filteredCCIs: any[]) => {
    if (channelConfigInstances) {
      if (categoriesFilterValue !== "All Categories") {
        filteredCCIs = filteredCCIs.filter(
          (s) => s.category === categoriesFilterValue
        );
      }

      if (servicesFilterValue !== "All Services") {
        filteredCCIs = filteredCCIs.filter(
          (s) => s.serviceName === servicesFilterValue
        );
      }

      if (languagesFilterValue !== "All Languages") {
        filteredCCIs = filteredCCIs.filter(
          (s) => s.language === languagesFilterValue
        );
      }

      if (connectionTypesFilterValue !== "All Connection Types") {
        filteredCCIs = filteredCCIs.filter(
          (s) => s.connectionType === connectionTypesFilterValue
        );
      }

      setFilteredChannelConfigInstances(filteredCCIs);
    }

    setdeleteIsOpen(false);
  };

  const handleSubmit = async (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();

    setCategoriesFilterValue("All Categories");
    setServicesFilterValue("All Services");
    setConnectionTypesFilterValue("All Connection Types");
    setLanguagesFilterValue("All Languages");

    applyFilters((channelConfigInstances || []).map((x) => x));

    if (filteredChannelConfigInstances) {
      try {
        const sts = await listStates();
        const tzs = await listTimezones();

        // eslint-disable-next-line camelcase
        const tz_sch = standardTimezones;

        // create agencyserviceconfigs
        for (const c of filteredChannelConfigInstances) {
          const states = sts.filter((x) => c.states.includes(x.nameLong));

          const tz = tzs.filter((i) => i.name === c.timezone)[0];

          // const tz_tag = tz_sch.filter((i) => i.name === c.timezone)[0].tag;

          const daily: any[] = [];
          // const sch = []
          for (const d of c.dailyConfig) {
            const offset = tz.utcOffset;

            const c = DateTime.now().toString().split("T")[0];

            let sTime = d.startTime;
            let eTime = d.endTime;

            if (sTime.length === 5) {
              sTime = sTime + ":00";
            }

            if (eTime.length === 5) {
              eTime = eTime + ":00";
            }

            const dtStart = DateTime.fromISO(c + "T" + sTime, {
              zone: "UTC" + offset,
            });
            const rezonedStart = dtStart.setZone("utc");

            const dtEnd = DateTime.fromISO(c + "T" + eTime, {
              zone: "UTC" + offset,
            });
            const rezonedEnd = dtEnd.setZone("utc");

            const startTime: string = rezonedStart
              .toString()
              .split("T")[1]
              .replace("Z", "");
            const endTime: string = rezonedEnd
              .toString()
              .split("T")[1]
              .replace("Z", "");

            daily.push({
              weekday: d.weekday,
              dailyConnectionLimit: d.limit,
              startTime: startTime,
              endTime: endTime,
            });
          }

          if (c.index < 0) {
            // new item

            const asc = {
              agencyId: parseInt(agencyId ?? "") || appState.agencyId,
              connectionType: c.connectionType,
              languageId: (c.language === "English" && 1) || 2,
              destinationType:
                (c.connectionType === "Phone" && "Phone number") ||
                (c.connectionType === "Online" &&
                  c.destinationIntakeUrl.length > 0 &&
                  "URL") ||
                (c.connectionType === "Video" && "RACE") ||
                "Email",

              timezone: tz,
              serviceId: c.serviceId,
              states: states,
              dailyConnectionLimits: daily,
              destination:
                (c.connectionType === "Phone" &&
                  "+1" +
                    c.destination.replaceAll("-", "").replaceAll("+1", "")) ||
                (c.connectionType === "Online" &&
                  c.destinationIntakeUrl.length > 0 &&
                  c.destinationIntakeUrl) ||
                (c.connectionType === "Video" && c.destination) ||
                c.destinationEmail,

              commsUrl: c.commsUrl,
              commsIntroduction: c.commsIntroduction,
              commsPhone: c.commsPhone,
              commsLogoUrl: c.commsLogoUrl,
              destinationEmail: c.destinationEmail,
              destinationIntakeUrl: c.destinationIntakeUrl,
              calendlyUrl: c.calendlyUrl,
              calendlyToken: c.calendlyToken,
            };

            createASC(asc);
          } else {
            if (c.updated) {
              const asc = {
                agencyServiceConfigId: c.index,
                agencyId: parseInt(agencyId ?? "") || appState.agencyId,
                connectionType: c.connectionType,
                language: c.language,
                destinationType:
                  (c.connectionType === "Phone" && "Phone number") ||
                  (c.connectionType === "Online" &&
                    c.destinationIntakeUrl.length > 0 &&
                    "URL") ||
                  (c.connectionType === "Video" && "RACE") ||
                  "Email",

                timezone: tz,
                serviceId: c.serviceId,
                states: states,
                dailyConnectionLimits: daily,
                destination:
                  (c.connectionType === "Phone" &&
                    "+1" +
                      c.destination.replaceAll("-", "").replaceAll("+1", "")) ||
                  (c.connectionType === "Online" &&
                    c.destinationIntakeUrl.length > 0 &&
                    c.destinationIntakeUrl) ||
                  (c.connectionType === "Video" && c.destination) ||
                  c.destinationEmail,

                commsUrl: c.commsUrl,
                commsIntroduction: c.commsIntroduction,
                commsPhone: c.commsPhone,
                commsLogoUrl: c.commsLogoUrl,
                destinationEmail: c.destinationEmail,
                raceSchedulesUUID: c.raceSchedulesUUID,
                destinationIntakeUrl: c.destinationIntakeUrl,
                calendlyUrl: c.calendlyUrl,
                calendlyToken: c.calendlyToken,
              };

              updateASC(asc);
            }
          }
        }
        setChannelConfigs(filteredChannelConfigInstances);

        if (appState.role === Role.admin) {
          navigate(pathname + "/updating-services");
        } else {
          navigate("/updating-services");
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not update service configurations",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    }

    setIsSubmitting(false);
  };

  const handleDeleteCloseConfirm = async () => {
    if (channelConfigInstances) {
      if ((deleteIndex ?? 0) > 0) {
        await deleteAgencyServiceConfig(deleteIndex ?? 0);
      }

      const newCCIs: any[] = (channelConfigInstances ?? []).filter(
        (s) => s.index !== deleteIndex
      );
      setChannelConfigInstances(newCCIs);

      const newFilteredCCIs = (filteredChannelConfigInstances ?? []).filter(
        (s) => s.index !== deleteIndex
      );

      setFilteredChannelConfigInstances(newFilteredCCIs);

      setDeleteIndex(0);
    }
    setdeleteIsOpen(false);
  };

  const handleEditClose = () => {
    const isError = validate();

    if (!isError) {
      setPageError(false);

      if (channelConfigInstances) {
        const config = [];
        const days: string[] = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        for (const i in days) {
          const c = {
            weekday: days[parseInt(i)],
            startTime: editingChannelConfig.startTimes[parseInt(i)],
            endTime: editingChannelConfig.endTimes[parseInt(i)],
            limit: editingChannelConfig.connectionLimits[parseInt(i)],
          };
          config.push(c);
        }
        const newCCI = {
          ...editingChannelConfig,
          dailyConfig: config,
          updated: true,
        };

        const oldCCIs: any[] = channelConfigInstances.map((x) => x);
        const ii = oldCCIs.findIndex((object) => {
          return object.index === editingId;
        });
        oldCCIs.splice(ii, 1, newCCI);
        setChannelConfigInstances(oldCCIs);

        // set up filters
        setCategoriesFilter([
          ...new Set(oldCCIs.map(({ category }) => category ?? -1)),
        ]);
        setServicesFilter([
          ...new Set(oldCCIs.map(({ serviceName }) => serviceName ?? -1)),
        ]);

        applyFilters(oldCCIs);
      }
      setEditingId(0);
      setIsOpenEditModal(false);

      setPhoneError("");
      setEmailError("");
      setLogoError("");
      setAgencyIntroError("");
      setValidationErrorsLimit(["", "", "", "", "", "", ""]);
      setValidationErrorsStartTime(["", "", "", "", "", "", ""]);
      setValidationErrorsEndTime(["", "", "", "", "", "", ""]);
      setPageError(false);
      setDuplicateError(false);
      setCalendlyError("");
      setDestinationUrlError("");
    } else {
      setPageError(true);
    }
  };

  const setAllChecked = (checked: boolean) => {
    if (checked) {
      if (channelConfigInstances) {
        const newCCI = { ...editingChannelConfig, states: states };
        setEditingChannelConfig(newCCI);
        setFilteredItems([]);
      }
    } else {
      if (channelConfigInstances) {
        const newCCI = { ...editingChannelConfig, states: [] };
        setEditingChannelConfig(newCCI);
        setFilteredItems(states);
      }
    }
  };

  const handleFilterChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (channelConfigInstances) {
      if (target.name === "category") {
        setCategoriesFilterValue(target.value);
      } else if (target.name === "service") {
        setServicesFilterValue(target.value);
      } else if (target.name === "connectionType") {
        setConnectionTypesFilterValue(target.value);
      } else if (target.name === "language") {
        setLanguagesFilterValue(target.value);
      }
    }
  };

  const getTemplate = (language: string, isVideo: boolean) => {
    if (emailTemplates) {
      for (const t of emailTemplates.templates ?? []) {
        if (!isVideo) {
          if (t.name === "client_" + language.toLowerCase()) {
            const { html } = t;

            // replace template tags
            if (html && channelConfigInstances && agency) {
              const agencyName = agency.name;
              const agencyPhone = editingChannelConfig.commsPhone ?? "";
              const agencyWebsite = editingChannelConfig.commsUrl ?? "";
              const agencyIntro = editingChannelConfig.commsIntroduction ?? "";
              const commsLogoUrl = editingChannelConfig.commsLogoUrl ?? "";

              return html
                .replaceAll("{{agency_name}}", agencyName ?? "")
                .replaceAll("{{agency_phone}}", agencyPhone)
                .replaceAll("{{agency_website}}", agencyWebsite)
                .replaceAll("{{agency_blurb}}", agencyIntro)
                .replaceAll("{{agency_logo}}", commsLogoUrl);
            }
          }
        } else {
          if (t.name === "client_" + language.toLowerCase() + "_video") {
            const { html } = t;

            // replace template tags
            if (html && channelConfigInstances && agency) {
              const agencyName = agency.name;
              const agencyPhone = editingChannelConfig.commsPhone ?? "";
              const agencyWebsite = editingChannelConfig.commsUrl ?? "";
              const agencyIntro = editingChannelConfig.commsIntroduction ?? "";
              const commsLogoUrl = editingChannelConfig.commsLogoUrl ?? "";

              return html
                .replaceAll("{{agency_name}}", agencyName ?? "")
                .replaceAll("{{agency_phone}}", agencyPhone)
                .replaceAll("{{agency_website}}", agencyWebsite)
                .replaceAll("{{agency_blurb}}", agencyIntro)
                .replaceAll("{{agency_logo}}", commsLogoUrl)
                .replaceAll("{{code}}", "12345678");
            }
          }
        }
      }

      return "<div/>";
    }
    return "<div/>";
  };

  return (
    <>
      <Box marginY="space60" display="flex" flexDirection="column">
        {success && (
          <>
            <Alert onDismiss={() => alert("dismissed")} variant="neutral">
              <Text as="span">
                <b>Services updated successfully.</b>{" "}
                {!phoneSuspended &&
                !onlineSuspended &&
                (!agencyVideoEnabled || !videoSuspended)
                  ? "You are now receiving connections"
                  : ""}
              </Text>
            </Alert>
            <Box paddingTop="space60"></Box>
          </>
        )}
        {!agencyVideoEnabled && (
          <>
            {phoneSuspended && onlineSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Phone and online connections are currently disabled.</b>{" "}
                    You are not currently receiving connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {phoneSuspended && !onlineSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Phone connections are currently disabled.</b> You are not
                    currently receiving connections by phone
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {!phoneSuspended && onlineSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Online connections are currently disabled.</b> You are
                    not currently receiving online connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
          </>
        )}
        {agencyVideoEnabled && (
          <>
            {phoneSuspended && onlineSuspended && videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>
                      Phone, online and video connections are currently
                      disabled.
                    </b>{" "}
                    You are not currently receiving connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {phoneSuspended && !onlineSuspended && !videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Phone connections are currently disabled.</b> You are not
                    currently receiving connections by phone
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {!phoneSuspended && onlineSuspended && !videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Online connections are currently disabled.</b> You are
                    not currently receiving online connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {!phoneSuspended && !onlineSuspended && videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Video connections are currently disabled.</b> You are not
                    currently receiving video connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {!phoneSuspended && onlineSuspended && videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Video and online connections are currently disabled.</b>{" "}
                    You are not currently receiving video or online connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {phoneSuspended && onlineSuspended && !videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Phone and online connections are currently disabled.</b>{" "}
                    You are not currently receiving phone or online connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
            {phoneSuspended && !onlineSuspended && videoSuspended && (
              <>
                <Alert onDismiss={() => alert("dismissed")} variant="warning">
                  <Text as="span">
                    <b>Phone and video connections are currently disabled.</b>{" "}
                    You are not currently receiving phone or video connections
                  </Text>
                </Alert>
                <Box paddingTop="space60"></Box>
              </>
            )}
          </>
        )}
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Heading as="h1" variant="heading10">
            Manage Services
          </Heading>
          <Box display="flex" flexDirection="column">
            <Stack orientation="horizontal" spacing="space80">
              {phoneSuspended && (
                <Button
                  variant="primary"
                  onClick={(e) => changeConnectionStatus("phone", 1)}
                  loading={!loaded}
                >
                  Enable phone connections
                </Button>
              )}
              {!phoneSuspended && (
                <Button
                  variant="destructive"
                  onClick={(e) => changeConnectionStatus("phone", 0)}
                  loading={!loaded}
                >
                  Disable phone connections
                </Button>
              )}
              {onlineSuspended && (
                <Button
                  variant="primary"
                  onClick={(e) => changeConnectionStatus("online", 1)}
                  loading={!loaded}
                >
                  Enable online connections
                </Button>
              )}
              {!onlineSuspended && (
                <Button
                  variant="destructive"
                  onClick={(e) => changeConnectionStatus("online", 0)}
                  loading={!loaded}
                >
                  Disable online connections
                </Button>
              )}
              {videoSuspended && (
                <Button
                  variant="primary"
                  onClick={(e) => changeConnectionStatus("video", 1)}
                  disabled={!agencyVideoEnabled}
                  loading={agencyVideoEnabled == undefined || !loaded}
                >
                  Enable video connections
                </Button>
              )}
              {!videoSuspended && (
                <Button
                  variant="destructive"
                  onClick={(e) => changeConnectionStatus("video", 0)}
                  disabled={
                    agencyVideoEnabled != undefined && !agencyVideoEnabled
                  }
                  loading={agencyVideoEnabled == undefined || !loaded}
                >
                  Disable video connections
                </Button>
              )}
            </Stack>
          </Box>
        </Box>

        <UnorderedList>
          <ListItem>Click the pencil icon to edit the service</ListItem>
          <ListItem>Click the trash can to remove a service</ListItem>
        </UnorderedList>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box
            display="flex"
            flexDirection="row"
            columnGap="space60"
            alignItems="flex-end"
          >
            <Box width="15vw">
              <Label htmlFor="category">Category</Label>
              <Select
                id="category"
                name="category"
                value={categoriesFilterValue}
                onChange={handleFilterChange}
              >
                <Option value="All Categories" key="allCategories">
                  All Categories
                </Option>
                {categoriesFilter &&
                  categoriesFilter?.map((f: any, index: number) => (
                    <Option value={f} key={"cat-" + index}>
                      {f}
                    </Option>
                  ))}
              </Select>
            </Box>

            <Box width="15vw">
              <Label htmlFor="services">Service</Label>
              <Select
                id="services"
                name="service"
                value={servicesFilterValue}
                onChange={handleFilterChange}
              >
                <Option value="All Services" key="allServices">
                  All Services
                </Option>
                {servicesFilter &&
                  servicesFilter?.map((f: any, index: number) => (
                    <Option value={f} key={"serv-" + index}>
                      {f}
                    </Option>
                  ))}
              </Select>
            </Box>

            <Box width="15vw">
              <Label htmlFor="connectionType">Connection Type</Label>
              <Select
                id="connectionType"
                name="connectionType"
                value={connectionTypesFilterValue}
                onChange={handleFilterChange}
              >
                <Option value="All Connection Types" key="allConnectionTypes">
                  All Connection Types
                </Option>
                {connectionTypesFilter &&
                  connectionTypesFilter?.map((f: any, index: number) => (
                    <Option value={f} key={"conn-" + index}>
                      {f}
                    </Option>
                  ))}
              </Select>
            </Box>

            <Box width="15vw">
              <Label htmlFor="language">Language</Label>
              <Select
                id="language"
                name="language"
                value={languagesFilterValue}
                onChange={handleFilterChange}
              >
                <Option value="All Languages" key="allLanguages">
                  All Languages
                </Option>
                {languagesFilter &&
                  languagesFilter?.map((f: any, index: number) => (
                    <Option value={f} key={"lang-" + index}>
                      {f}
                    </Option>
                  ))}
              </Select>
            </Box>
          </Box>

          <Box>
            <Button
              variant="primary"
              onClick={(e) =>
                applyFilters((channelConfigInstances || []).map((x) => x))
              }
            >
              <FilterIcon decorative={false} title="Apply filters" />
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box>
          <Scroller />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box display="flex" flexDirection="row">
              <Box display="flex" justifyContent="flex-end" marginY="space60">
                <Button variant="primary" onClick={handleAddNewService}>
                  <PlusIcon decorative={false} title="Add new service" />
                  Add New Service
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginY="space60"
                paddingLeft="space50"
              >
                <Button variant="secondary" onClick={handleCopyService}>
                  <CopyIcon decorative={false} title="Copy service" />
                  Copy Service
                </Button>
              </Box>
            </Box>

            <Box display="flex" justifyContent="flex-end" marginY="space60">
              <Button variant="primary" type="submit" loading={isSubmitting}>
                Save
              </Button>
            </Box>
          </Box>

          <Table>
            <THead>
              <Tr>
                <Th>Actions</Th>
                <Th>Category</Th>
                <Th>Service</Th>
                <Th>Connection Type</Th>
                <Th>Language</Th>
                <Th>States Operational</Th>
                <Th>Destination</Th>
                <Th>Timezone</Th>
                <Td>Day of Week</Td>
                <Td>Start Time</Td>
                <Td>End Time</Td>
                <Td>Daily Connection Limit</Td>
              </Tr>
            </THead>

            {loaded ? (
              <TBody>
                {loaded &&
                  filteredChannelConfigInstances &&
                  filteredChannelConfigInstances?.map(
                    (c: any, index: number) => (
                      <>
                        <Tr key={"tr_" + index}>
                          <Td rowSpan={7} key={"td_0_" + index}>
                            <Stack orientation="vertical" spacing="space60">
                              <Button
                                variant="secondary"
                                onClick={(e) =>
                                  handleEditOpen(c.index, undefined)
                                }
                              >
                                <EditIcon
                                  decorative={false}
                                  title="Edit service config"
                                />
                              </Button>

                              <Button
                                variant="destructive"
                                onClick={(e) => handleDeleteOpen(c.index)}
                                key={"delete_" + index}
                              >
                                <DeleteIcon
                                  decorative={false}
                                  title="Delete service config"
                                />
                              </Button>
                            </Stack>
                          </Td>
                          <Td rowSpan={7}>{c.category}</Td>
                          <Td rowSpan={7}>{c.serviceName}</Td>
                          <Td rowSpan={7}>{c.connectionType}</Td>
                          <Td rowSpan={7}>{c.language}</Td>
                          <Td rowSpan={7}>
                            {c.states.slice(0, 3).join(", ") + "..."}
                            {c.states.length - 3 > 0 &&
                              c.states.length - 3 + " others"}
                          </Td>
                          <Td rowSpan={7}>{c.destination}</Td>
                          <Td rowSpan={7}>{c.timezone}</Td>

                          {loaded &&
                            filteredChannelConfigInstances &&
                            c.dailyConfig && (
                              <>
                                <Td>{c.dailyConfig[0].weekday} </Td>
                                <Td>
                                  {" "}
                                  {(c.dailyConfig[0].startTime ===
                                    c.dailyConfig[0].endTime &&
                                    c.dailyConfig[0].endTime === "00:00:00" &&
                                    "N/A") ||
                                    (c.dailyConfig[0].startTime ===
                                      "--:--:--" &&
                                      "N/A") ||
                                    formatTime(c.dailyConfig[0].startTime)}
                                </Td>
                                <Td>
                                  {(c.dailyConfig[0].startTime ===
                                    c.dailyConfig[0].endTime &&
                                    c.dailyConfig[0].endTime === "00:00:00" &&
                                    "N/A") ||
                                    (c.dailyConfig[0].endTime === "--:--:--" &&
                                      "N/A") ||
                                    formatTime(c.dailyConfig[0].endTime)}
                                </Td>
                                <Td>
                                  {(c.dailyConfig[0].startTime ===
                                    c.dailyConfig[0].endTime &&
                                    c.dailyConfig[0].endTime === "00:00:00" &&
                                    "N/A") ||
                                    c.dailyConfig[0].limit}
                                </Td>
                              </>
                            )}
                        </Tr>

                        {loaded &&
                          filteredChannelConfigInstances &&
                          filteredChannelConfigInstances[index].dailyConfig
                            .filter((x: any) => x.weekday !== "Monday")
                            .map((cd: any) => (
                              <>
                                <Tr>
                                  <Td>{cd.weekday}</Td>
                                  <Td>
                                    {(cd.startTime === cd.endTime &&
                                      cd.endTime === "00:00:00" &&
                                      "N/A") ||
                                      (cd.startTime === "--:--:--" && "N/A") ||
                                      formatTime(cd.startTime)}
                                  </Td>
                                  <Td>
                                    {(cd.startTime === cd.endTime &&
                                      cd.endTime === "00:00:00" &&
                                      "N/A") ||
                                      (cd.endTime === "--:--:--" && "N/A") ||
                                      formatTime(cd.endTime)}
                                  </Td>
                                  <Td>
                                    {(cd.startTime === cd.endTime &&
                                      cd.endTime === "00:00:00" &&
                                      "N/A") ||
                                      cd.limit}
                                  </Td>
                                </Tr>
                              </>
                            ))}
                      </>
                    )
                  )}
                <Tr>{""}</Tr>
              </TBody>
            ) : (
              <TBody>
                <TableSkeletonLoader numberOfTr={4} numberOfTd={12} />
              </TBody>
            )}
          </Table>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          position="relative"
          marginY="space60"
          paddingBottom="space100"
        >
          <Button variant="secondary">Cancel</Button>

          <Button variant="primary" type="submit">
            Save
          </Button>
        </Box>
      </form>

      <Modal
        isOpen={isOpenEditModal}
        onDismiss={handleEditCancel}
        size="wide"
        ariaLabelledby="modal1"
      >
        <Box paddingTop="space60">
          <ModalHeader>
            <ModalHeading as="h2" id="modal1">
              Edit Service Configuration
            </ModalHeading>
          </ModalHeader>
        </Box>

        {loaded && editingChannelConfig && (
          <ModalBody>
            <>
              {pageError && (
                <Box paddingTop="space60" paddingBottom="space60">
                  <Alert variant="error">
                    <Text as="span">
                      Validation errors have been found on this service, please
                      check these before continuing
                    </Text>
                  </Alert>
                </Box>
              )}

              {duplicateError && (
                <Box paddingTop="space60" paddingBottom="space60">
                  <Alert variant="error">
                    <Text as="span">
                      A service configuration already exists for this service,
                      language and connection type combination. To change this
                      setup, please cancel out of this form and open the
                      appropriate service configuration
                    </Text>
                  </Alert>
                </Box>
              )}

              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <Box width="40%" marginY="space60">
                  <Label required htmlFor="clientId">
                    Service
                  </Label>
                  {editingChannelConfig && (
                    <Select
                      name="serviceId"
                      id="serviceId"
                      value={editingChannelConfig.serviceId}
                      onChange={handleChange}
                    >
                      <Option key={-1} value="0">
                        Select a Service
                      </Option>

                      {services && loaded
                        ? services &&
                          services.map((s: any, index: number) => (
                            <Option key={index} value={s.serviceId}>
                              {s.name}
                            </Option>
                          ))
                        : null!}
                    </Select>
                  )}
                </Box>
                <Box width="40%" marginY="space60">
                  {editingChannelConfig && (
                    <RadioGroup
                      name="connectionType"
                      value={editingChannelConfig.connectionType}
                      legend="Connection Type"
                      onChange={(e) => {
                        handleConnectionTypeChange(e);
                      }}
                    >
                      <Radio
                        id="Phone"
                        value="Phone"
                        name="Phone"
                        disabled={
                          !editingChannelConfig.availableConnectionTypes?.includes(
                            "Phone"
                          )
                        }
                      >
                        Phone
                      </Radio>
                      <Radio
                        id="Online"
                        value="Online"
                        name="Online"
                        disabled={
                          !editingChannelConfig.availableConnectionTypes?.includes(
                            "Online"
                          )
                        }
                      >
                        Online
                      </Radio>
                      <Radio
                        id="Video"
                        value="Video"
                        name="Video"
                        disabled={
                          !agencyVideoEnabled ||
                          !editingChannelConfig.availableConnectionTypes?.includes(
                            "Video"
                          )
                        }
                      >
                        Video
                      </Radio>
                    </RadioGroup>
                  )}
                  {connectionTypeError.length > 0 && (
                    <HelpText variant="error">{connectionTypeError}</HelpText>
                  )}
                </Box>
                <Box width="40%" marginY="space60">
                  {editingChannelConfig && (
                    <RadioGroup
                      name="language"
                      value={editingChannelConfig.language}
                      legend="Language"
                      onChange={(e) => {
                        handleLanguageChange(e);
                      }}
                    >
                      <Radio id="English" value="English" name="English">
                        English
                      </Radio>
                      <Radio id="Spanish" value="Spanish" name="Spanish">
                        Spanish
                      </Radio>
                    </RadioGroup>
                  )}
                </Box>

                {editingChannelConfig.connectionType === "Phone" && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="destination">
                      Phone number
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="destination"
                        name="destination"
                        onChange={handleChange}
                        value={editingChannelConfig.destination.replace(
                          "+1",
                          ""
                        )}
                        required
                        hasError={phoneError.length > 0}
                      />
                    )}
                    {phoneError.length > 0 && (
                      <HelpText variant="error">{phoneError}</HelpText>
                    )}
                  </Box>
                )}

                {["Online", "Video"].includes(
                  editingChannelConfig.connectionType
                ) && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="destinationEmail">
                      {" "}
                      Agency Email
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="destinationEmail"
                        name="destinationEmail"
                        onChange={handleChange}
                        value={editingChannelConfig.destinationEmail}
                        required
                        hasError={emailError.length > 0}
                      />
                    )}
                    {emailError.length > 0 && (
                      <HelpText variant="error">{emailError}</HelpText>
                    )}
                  </Box>
                )}

                {editingChannelConfig.connectionType === "Video" && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="destinationVideo">
                      Destination
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="destinationVideo"
                        name="destinationVideo"
                        value={editingChannelConfig.destination}
                        required
                        disabled
                      />
                    )}
                    {emailError.length > 0 && (
                      <HelpText variant="error">{emailError}</HelpText>
                    )}
                  </Box>
                )}

                {editingChannelConfig.connectionType === "Online" && (
                  <Box width="40%" marginY="space60">
                    <Label htmlFor="destinationIntakeUrl">
                      Intake Form URL
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="destinationIntakeUrl"
                        name="destinationIntakeUrl"
                        onChange={handleChange}
                        value={editingChannelConfig.destinationIntakeUrl}
                      />
                    )}
                    {destinationUrlError.length > 0 && (
                      <HelpText variant="error">{destinationUrlError}</HelpText>
                    )}
                  </Box>
                )}

                {["Online"].includes(editingChannelConfig.connectionType) &&
                  editingChannelConfig.showBooking === 1 && (
                    <Box width="40%" marginY="space60">
                      <Label htmlFor="calendlyUrl">Calendly URL</Label>
                      {channelConfigInstances && (
                        <Input
                          type="text"
                          id="calendlyUrl"
                          name="calendlyUrl"
                          onChange={handleChange}
                          value={editingChannelConfig.calendlyUrl}
                        />
                      )}
                      {calendlyError.length > 0 && (
                        <HelpText variant="error">{calendlyError}</HelpText>
                      )}
                      <HelpText variant="default">
                        For details on how to set up your Calendly instance for
                        locator,{" "}
                        <a href="https://app.tango.us/app/workflow/Locator---Online-Booking-with-Calendly-a3471b8949144bdebfc63f1639970905">
                          click here
                        </a>
                      </HelpText>
                    </Box>
                  )}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {["Video"].includes(editingChannelConfig.connectionType) &&
                  editingChannelConfig.showBooking === 1 && (
                    <>
                      <Box width="40%" marginY="space60">
                        <Label htmlFor="calendlyUrl">Calendly URL</Label>
                        {channelConfigInstances && (
                          <Input
                            type="text"
                            id="calendlyUrl"
                            name="calendlyUrl"
                            onChange={handleChange}
                            value={editingChannelConfig.calendlyUrl}
                          />
                        )}
                        {calendlyError.length > 0 && (
                          <HelpText variant="error">{calendlyError}</HelpText>
                        )}
                        <HelpText variant="default">
                          For details on how to set up your Calendly instance
                          for locator,{" "}
                          <a href="https://app.tango.us/app/workflow/Locator---Online-Booking-with-Calendly-a3471b8949144bdebfc63f1639970905">
                            click here
                          </a>
                        </HelpText>
                      </Box>
                      <Box width="40%" marginY="space60">
                        <Label htmlFor="calendlyToken">Calendly Token</Label>
                        {channelConfigInstances && (
                          <Input
                            type="text"
                            id="calendlyToken"
                            name="calendlyToken"
                            onChange={handleChange}
                            value={editingChannelConfig.calendlyToken}
                          />
                        )}
                        {calendlyTokenError.length > 0 && (
                          <HelpText variant="error">
                            {calendlyTokenError}
                          </HelpText>
                        )}
                      </Box>
                    </>
                  )}

                {["Online", "Video"].includes(
                  editingChannelConfig.connectionType
                ) && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="commsUrl">
                      Agency Website for Client Email
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="commsUrl"
                        name="commsUrl"
                        onChange={handleChange}
                        value={editingChannelConfig.commsUrl}
                        required
                      />
                    )}
                  </Box>
                )}

                {["Online", "Video"].includes(
                  editingChannelConfig.connectionType
                ) && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="commsPhone">
                      Agency Phone
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="commsPhone"
                        name="commsPhone"
                        onChange={handleChange}
                        value={editingChannelConfig.commsPhone}
                        required
                      />
                    )}
                  </Box>
                )}

                {["Online", "Video"].includes(
                  editingChannelConfig.connectionType
                ) && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="commsLogoUrl">
                      Agency Logo
                    </Label>
                    {channelConfigInstances && (
                      <Input
                        type="text"
                        id="commsLogoUrl"
                        name="commsLogoUrl"
                        onChange={handleChange}
                        value={editingChannelConfig.commsLogoUrl}
                        required
                        hasError={logoError.length > 0}
                      />
                    )}
                    {logoError.length > 0 && (
                      <HelpText variant="error">{logoError}</HelpText>
                    )}
                  </Box>
                )}

                {["Online", "Video"].includes(
                  editingChannelConfig.connectionType
                ) && (
                  <Box width="40%" marginY="space60">
                    <Label required htmlFor="introduction">
                      Agency Introduction
                    </Label>
                    <TextArea
                      required
                      id="introduction"
                      name="commsIntroduction"
                      value={editingChannelConfig.commsIntroduction}
                      defaultValue={editingChannelConfig.commsIntroduction}
                      hasError={agencyIntroError.length > 0}
                      onChange={(e: any) => {
                        handleTextChange(e);
                      }}
                    />
                    {agencyIntroError.length > 0 && (
                      <HelpText variant="error">{agencyIntroError}</HelpText>
                    )}
                  </Box>
                )}

                {["Online", "Video"].includes(
                  editingChannelConfig.connectionType
                ) && (
                  <Box width="40%" marginY="space60">
                    <Button variant="secondary" onClick={handleOpen}>
                      Preview Email
                      <ShowIcon decorative={false} title="Preview Email Icon" />
                    </Button>
                  </Box>
                )}

                <Box width="40%" marginY="space60">
                  <Label required htmlFor="timezone">
                    Timezone
                  </Label>
                  {editingChannelConfig && (
                    <Select
                      id="timezone"
                      name="timezone"
                      value={editingChannelConfig.timezone}
                      onChange={handleChange}
                    >
                      {standardTimezones.map((tz) => (
                        <Option value={tz.tag} key={tz.tag}>
                          {tz.tag}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  width="100%"
                >
                  <Box display="flex" width="100%" marginY="space60">
                    {loaded && editingChannelConfig && (
                      <Box
                        marginBottom="space40"
                        position="relative"
                        width="50%"
                        paddingRight="space60"
                      >
                        <Label htmlFor={inputId} {...getLabelProps()}>
                          States
                        </Label>
                        <Checkbox
                          key="select_all"
                          id="select_all"
                          checked={
                            editingChannelConfig.states.length === states.length
                          }
                          indeterminate={
                            editingChannelConfig.states.length !==
                              states.length &&
                            editingChannelConfig.states.length !== 0
                          }
                          onChange={(e) => setAllChecked(e.target.checked)}
                        >
                          Select all
                        </Checkbox>
                        <Box {...getComboboxProps({ role: "combobox" })}>
                          <Input
                            id={inputId}
                            type="text"
                            {...getInputProps({
                              ...getDropdownProps({
                                preventKeyAction: isOpen,
                                ...getToggleButtonProps({ tabIndex: 0 }),
                              }),
                            })}
                            value={selectedItem || ""}
                          />
                        </Box>
                        <ComboboxListbox hidden={!isOpen} {...getMenuProps()}>
                          <ComboboxListboxGroup>
                            {filteredItems.map((filteredItem, index) => (
                              <ComboboxListboxOption
                                highlighted={highlightedIndex === index}
                                variant="default"
                                {...getItemProps({
                                  item: filteredItem,
                                  index,
                                  key: seed("filtered-item-" + filteredItem),
                                })}
                                key={"filtered-item-" + filteredItem}
                              >
                                {filteredItem}
                              </ComboboxListboxOption>
                            ))}
                          </ComboboxListboxGroup>
                        </ComboboxListbox>
                        {statesError.length > 0 && (
                          <HelpText variant="error">{statesError}</HelpText>
                        )}
                      </Box>
                    )}
                    <Box
                      padding="space60"
                      width="80%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      borderColor="colorBorder"
                      borderWidth="borderWidth10"
                      borderStyle="solid"
                    >
                      <Heading as="h3" variant="heading30">
                        Selected States
                      </Heading>
                      <Separator
                        orientation="horizontal"
                        verticalSpacing="space20"
                      />
                      {editingChannelConfig && (
                        <Box marginY="space60">
                          <FormPillGroup
                            {...formPillState}
                            aria-label="Selected components"
                          >
                            {editingChannelConfig.states.map(
                              (item: any, index: number) => {
                                return (
                                  <FormPill
                                    {...getSelectedItemProps({
                                      selectedItem,
                                      index,
                                      key: "selected-item-" + item,
                                    })}
                                    key={"selected-item-" + item}
                                    tabIndex={null}
                                    {...formPillState}
                                    onDismiss={() =>
                                      handleRemoveItemOnClick(item)
                                    }
                                  >
                                    {item}
                                  </FormPill>
                                );
                              }
                            )}
                          </FormPillGroup>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {loaded && (
                  <Box marginY="space60">
                    <Table>
                      <THead>
                        <Tr>
                          <Th>Days</Th>
                          <Th width="15vw">From</Th>
                          <Th width="15vw">To</Th>
                          <Th width="5vw">24 Hours</Th>
                          <Th width="5vw">Offline</Th>
                          <Th width="15vw">Connections</Th>
                        </Tr>
                      </THead>
                      <TBody>
                        <Tr>
                          <Td>Monday</Td>
                          <Td>
                            <TimePicker
                              id="monday#startTime"
                              name="monday#startTimes#0"
                              value={editingChannelConfig.startTimes[0]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[0]}
                              hasError={validationErrorsStartTime[0].length > 0}
                            />
                            {validationErrorsStartTime[0].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[0]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="monday#endTime"
                              name="monday#endTimes#0"
                              value={editingChannelConfig.endTimes[0]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[0]}
                              hasError={validationErrorsEndTime[0].length > 0}
                            />
                            {validationErrorsEndTime[0].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[0]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="monday#override#0"
                              id="monday#override#0"
                              checked={editingChannelConfig.timeOverride[0]}
                              value="monday#override#0"
                              name="monday#override#0"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="monday#offline#0"
                              id="monday#offline#0"
                              checked={editingChannelConfig.offline[0]}
                              value="monday#offline#0"
                              name="monday#offline#0"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="monday#connectionlimits#0"
                              name="monday#connectionlimits#0"
                              defaultValue={
                                editingChannelConfig.connectionLimits[0]
                              }
                              onChange={handleTimeChange}
                              disabled={editingChannelConfig.offline[0]}
                              hasError={validationErrorsLimit[0].length > 0}
                            />
                            {validationErrorsLimit[0].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[0]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Tuesday</Td>
                          <Td>
                            <TimePicker
                              id="tuesday#startTime"
                              name="tuesday#startTimes#1"
                              value={editingChannelConfig.startTimes[1]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[1]}
                              hasError={validationErrorsStartTime[1].length > 0}
                            />
                            {validationErrorsStartTime[1].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[1]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="tuesday#endTime"
                              name="tuesday#endTimes#1"
                              value={editingChannelConfig.endTimes[1]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[1]}
                              hasError={validationErrorsEndTime[1].length > 0}
                            />
                            {validationErrorsEndTime[1].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[1]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="tuesday#override#1"
                              id="tuesday#override#1"
                              checked={editingChannelConfig.timeOverride[1]}
                              value="tuesday#override#1"
                              name="tuesday#override#1"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="tuesday#offline#1"
                              id="tuesday#offline#1"
                              checked={editingChannelConfig.offline[1]}
                              value="tuesday#offline#1"
                              name="tuesday#offline#1"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="tuesday#connectionlimits#1"
                              name="tuesday#connectionlimits#1"
                              onChange={handleTimeChange}
                              defaultValue={
                                editingChannelConfig.connectionLimits[1]
                              }
                              disabled={editingChannelConfig.offline[1]}
                              hasError={validationErrorsLimit[1].length > 0}
                            />
                            {validationErrorsLimit[1].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[1]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Wednesday</Td>
                          <Td>
                            <TimePicker
                              id="wednesday#startTime"
                              name="wednesday#startTimes#2"
                              value={editingChannelConfig.startTimes[2]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[2]}
                              hasError={validationErrorsStartTime[2].length > 0}
                            />
                            {validationErrorsStartTime[2].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[2]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="wednesday#endTime"
                              name="wednesday#endTimes#2"
                              value={editingChannelConfig.endTimes[2]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[2]}
                              hasError={validationErrorsEndTime[2].length > 0}
                            />
                            {validationErrorsEndTime[2].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[2]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="wednesday#override#2"
                              id="wednesday#override#2"
                              checked={editingChannelConfig.timeOverride[2]}
                              value="wednesday#override#2"
                              name="wednesday#override#2"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="wednesday#offline#2"
                              id="wednesday#offline#2"
                              checked={editingChannelConfig.offline[2]}
                              value="wednesday#offline#2"
                              name="wednesday#offline#2"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="wednesday#connectionlimits#2"
                              name="wednesday#connectionlimits#2"
                              defaultValue={
                                editingChannelConfig.connectionLimits[2]
                              }
                              onChange={handleTimeChange}
                              disabled={editingChannelConfig.offline[2]}
                              hasError={validationErrorsLimit[2].length > 0}
                            />
                            {validationErrorsLimit[2].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[2]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Thursday</Td>
                          <Td>
                            <TimePicker
                              id="thursday#startTime"
                              name="thursday#startTimes#3"
                              value={editingChannelConfig.startTimes[3]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[3]}
                              hasError={validationErrorsStartTime[3].length > 0}
                            />
                            {validationErrorsStartTime[3].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[3]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="thursday#endTime"
                              name="thursday#endTimes#3"
                              value={editingChannelConfig.endTimes[3]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[3]}
                              hasError={validationErrorsEndTime[3].length > 0}
                            />
                            {validationErrorsEndTime[3].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[3]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="thursday#override#3"
                              id="thursday#override#3"
                              checked={editingChannelConfig.timeOverride[3]}
                              value="thursday#override#3"
                              name="thursday#override#3"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="thursday#offline#3"
                              id="thursday#offline#3"
                              checked={editingChannelConfig.offline[3]}
                              value="thursday#offline#3"
                              name="thursday#offline#3"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="thursday#connectionlimits#3"
                              name="thursday#connectionlimits#3"
                              defaultValue={
                                editingChannelConfig.connectionLimits[3]
                              }
                              onChange={handleTimeChange}
                              disabled={editingChannelConfig.offline[3]}
                              hasError={validationErrorsLimit[3].length > 0}
                            />
                            {validationErrorsLimit[3].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[3]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Friday</Td>
                          <Td>
                            <TimePicker
                              id="friday#startTime"
                              name="friday#startTimes#4"
                              value={editingChannelConfig.startTimes[4]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[4]}
                              hasError={validationErrorsStartTime[4].length > 4}
                            />
                            {validationErrorsStartTime[4].length > 4 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[4]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="friday#endTime"
                              name="friday#endTimes#4"
                              value={editingChannelConfig.endTimes[4]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[4]}
                              hasError={validationErrorsEndTime[4].length > 0}
                            />
                            {validationErrorsEndTime[4].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[4]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="friday#override#4"
                              id="friday#override#4"
                              checked={editingChannelConfig.timeOverride[4]}
                              value="friday#override#4"
                              name="friday#override#4"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="friday#offline#4"
                              id="friday#offline#4"
                              checked={editingChannelConfig.offline[4]}
                              value="friday#offline#4"
                              name="friday#offline#4"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="friday#connectionlimits#4"
                              name="friday#connectionlimits#4"
                              defaultValue={
                                editingChannelConfig.connectionLimits[4]
                              }
                              onChange={handleTimeChange}
                              disabled={editingChannelConfig.offline[4]}
                              hasError={validationErrorsLimit[4].length > 0}
                            />
                            {validationErrorsLimit[4].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[4]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Saturday</Td>
                          <Td>
                            <TimePicker
                              id="saturday#startTime"
                              name="saturday#startTimes#5"
                              value={editingChannelConfig.startTimes[5]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[5]}
                              hasError={validationErrorsStartTime[5].length > 0}
                            />
                            {validationErrorsStartTime[5].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[5]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="saturday#endTime"
                              name="saturday#endTimes#5"
                              value={editingChannelConfig.endTimes[5]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[5]}
                              hasError={validationErrorsEndTime[5].length > 0}
                            />
                            {validationErrorsEndTime[5].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[5]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="saturday#override#5"
                              id="saturday#override#5"
                              checked={editingChannelConfig.timeOverride[5]}
                              value="saturday#override#5"
                              name="saturday#override#5"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="saturday#offline#5"
                              id="saturday#offline#5"
                              checked={editingChannelConfig.offline[5]}
                              value="saturday#offline#5"
                              name="saturday#offline#5"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="saturday#connectionlimits#5"
                              name="saturday#connectionlimits#5"
                              defaultValue={
                                editingChannelConfig.connectionLimits[5]
                              }
                              onChange={handleTimeChange}
                              disabled={editingChannelConfig.offline[5]}
                              hasError={validationErrorsLimit[5].length > 0}
                            />
                            {validationErrorsLimit[5].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[5]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Sunday</Td>
                          <Td>
                            <TimePicker
                              id="sunday#startTime"
                              name="sunday#startTimes#6"
                              value={editingChannelConfig.startTimes[6]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[6]}
                              hasError={validationErrorsStartTime[6].length > 0}
                            />
                            {validationErrorsStartTime[6].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsStartTime[6]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <TimePicker
                              id="sunday#endTime"
                              name="sunday#endTimes#6"
                              value={editingChannelConfig.endTimes[6]}
                              onChange={handleTimeChange}
                              enterKeyHint={undefined}
                              disabled={editingChannelConfig.offline[6]}
                              hasError={validationErrorsEndTime[6].length > 0}
                            />
                            {validationErrorsEndTime[6].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsEndTime[6]}
                              </HelpText>
                            )}
                          </Td>
                          <Td>
                            <Checkbox
                              key="sunday#override#6"
                              id="sunday#override#6"
                              checked={editingChannelConfig.timeOverride[6]}
                              value="sunday#override#6"
                              name="sunday#override#6"
                              onClick={(e) => handleEnableDisable24hours(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Checkbox
                              key="sunday#offline#6"
                              id="sunday#offline#6"
                              checked={editingChannelConfig.offline[6]}
                              value="sunday#offline#6"
                              name="sunday#offline#6"
                              onClick={(e) => handleEnableDisableOffline(e)}
                            >
                              {null!}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Input
                              type="number"
                              id="sunday#connectionlimits#6"
                              name="sunday#connectionlimits#6"
                              defaultValue={
                                editingChannelConfig.connectionLimits[6]
                              }
                              onChange={handleTimeChange}
                              disabled={editingChannelConfig.offline[6]}
                              hasError={validationErrorsLimit[6].length > 0}
                            />
                            {validationErrorsLimit[6].length > 0 && (
                              <HelpText variant="error">
                                {validationErrorsLimit[6]}
                              </HelpText>
                            )}
                          </Td>
                        </Tr>
                      </TBody>
                    </Table>
                  </Box>
                )}
              </Box>
            </>
          </ModalBody>
        )}
        <ModalFooter>
          <ModalFooterActions>
            <Button variant="secondary" onClick={handleEditCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleEditClose}>
              Submit
            </Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteIsOpen}
        onDismiss={handleDeleteClose}
        size="default"
        ariaLabelledby={deleteModalHeadingID}
      >
        <ModalHeader>
          <ModalHeading as="h2" id={deleteModalHeadingID}>
            Delete Service
          </ModalHeading>
        </ModalHeader>

        <ModalBody>
          <Text as="p">
            Are you sure want to delete this service configuration?
          </Text>
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Back
            </Button>
            <Button variant="destructive" onClick={handleDeleteCloseConfirm}>
              Delete Service
            </Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

      {loaded && (
        <Modal
          isOpen={isOpenCopyModal}
          onDismiss={handleCopyClose}
          size="wide"
          ariaLabelledby="Email Preview"
        >
          <ModalHeader>
            <ModalHeading as="h2" id="emailpreview">
              Copy Service
            </ModalHeading>
          </ModalHeader>

          <ModalBody>
            <Box width="15vw">
              <Label htmlFor="serviceconfig">
                Service Configuration to Copy
              </Label>
              <Select
                id="serviceconfig"
                name="serviceconfig"
                onChange={handleCopySelection}
              >
                <Option
                  value="All Service Configurations"
                  key="allServiceConfigurations"
                >
                  All Service Configurations
                </Option>
                {copySelection &&
                  copySelection?.map((f: any, index: number) => (
                    <Option value={f.id} key={"copy-" + index}>
                      {f.label}
                    </Option>
                  ))}
              </Select>
            </Box>
          </ModalBody>

          <ModalFooter>
            <ModalFooterActions>
              <Button variant="secondary" onClick={handleCopyClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleCopyContinue}>
                Copy
              </Button>
            </ModalFooterActions>
          </ModalFooter>
        </Modal>
      )}

      {loaded && editingChannelConfig && (
        <Modal
          isOpen={isOpenModal}
          onDismiss={handleClose}
          size="wide"
          ariaLabelledby="Email Preview"
        >
          <ModalHeader>
            <ModalHeading as="h2" id="emailpreview">
              Email Preview
            </ModalHeading>
          </ModalHeader>

          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: getTemplate(
                  editingChannelConfig.language,
                  editingChannelConfig.connectionType == "Video"
                ),
              }}
            />
          </ModalBody>

          <ModalFooter>
            <ModalFooterActions>
              <Button variant="primary" onClick={handleClose}>
                Close Preview
              </Button>
            </ModalFooterActions>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
