import { ChangeEvent, FC } from "react";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Button } from "@twilio-paste/core/button";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { Stack } from "@twilio-paste/core/stack";
import { DatePicker } from "@twilio-paste/core/date-picker";
import { Box } from "@twilio-paste/core/box";
import { HelpText } from "@twilio-paste/core/help-text";

interface ConnectionFiltersProps {
  loading: boolean;
  startDate: string;
  endDate: string;
  preDateError: string;
  dateError: string;
  servicesFilter: string[];
  servicesFilterValue: string;
  connectionTypesFilter: string[];
  connectionTypesFilterValue: string;
  languagesFilter: string[];
  languagesFilterValue: string;
  onDateChange: (name: string, value: string, format: string) => void;
  onFilterChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onApplyFilters: () => void;
}

export const ConnectionFilters: FC<ConnectionFiltersProps> = ({
  loading,
  startDate,
  endDate,
  preDateError,
  dateError,
  servicesFilter,
  servicesFilterValue,
  connectionTypesFilter,
  connectionTypesFilterValue,
  languagesFilter,
  languagesFilterValue,
  onDateChange,
  onFilterChange,
  onApplyFilters,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      marginY="space60"
    >
      <Box
        display="flex"
        flexDirection="row"
        columnGap="space60"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="flex-start" flexDirection="row">
          <Stack orientation="horizontal" spacing="space80">
            <Box>
              <Label htmlFor="startDatePicker">Start date</Label>
              <DatePicker
                hasError={preDateError.length > 0}
                id="startDatePicker"
                name="startDatePicker"
                onChange={(evt) =>
                  onDateChange(
                    evt.target.name,
                    evt.target.value,
                    "yyyy-MM-dd"
                  )
                }
                enterKeyHint={null!}
                value={startDate}
              />
              {preDateError.length > 0 && (
                <HelpText variant="error">{preDateError}</HelpText>
              )}
            </Box>
            <Box>
              <Label htmlFor="endDatePicker">End date</Label>
              <DatePicker
                hasError={dateError.length > 0}
                id="endDatePicker"
                name="endDatePicker"
                onChange={(evt) =>
                  onDateChange(
                    evt.target.name,
                    evt.target.value,
                    "yyyy-MM-dd"
                  )
                }
                enterKeyHint={null!}
                value={endDate}
              />
              {dateError.length > 0 && (
                <HelpText variant="error">{dateError}</HelpText>
              )}
            </Box>
          </Stack>
        </Box>

        <Box width="15vw">
          <Label htmlFor="services">Service</Label>
          <Select
            id="services"
            name="service"
            value={servicesFilterValue}
            onChange={onFilterChange}
          >
            <Option value="All Services" key="allServices">
              All Services
            </Option>
            {servicesFilter?.map((f: string, index: number) => (
              <Option value={f} key={`serv-${index}`}>
                {f}
              </Option>
            ))}
          </Select>
        </Box>

        <Box width="15vw">
          <Label htmlFor="connectionType">Connection Type</Label>
          <Select
            id="connectionType"
            name="connectionType"
            value={connectionTypesFilterValue}
            onChange={onFilterChange}
          >
            <Option value="All Connection Types" key="allConnectionTypes">
              All Connection Types
            </Option>
            {connectionTypesFilter?.map((f: string, index: number) => (
              <Option value={f} key={`conn-${index}`}>
                {f}
              </Option>
            ))}
          </Select>
        </Box>

        <Box width="15vw">
          <Label htmlFor="language">Language</Label>
          <Select
            id="language"
            name="language"
            value={languagesFilterValue}
            onChange={onFilterChange}
          >
            <Option value="All Languages" key="allLanguages">
              All Languages
            </Option>
            {languagesFilter?.map((f: string, index: number) => (
              <Option value={f} key={`lang-${index}`}>
                {f}
              </Option>
            ))}
          </Select>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        height="fit-content"
        paddingTop="space70"
      >
        {loading ? (
          <Button variant="primary" loading>
            Apply
          </Button>
        ) : (
          <Button variant="primary" onClick={onApplyFilters}>
            <FilterIcon decorative={false} title="Apply filters" />
            Apply
          </Button>
        )}
      </Box>
    </Box>
  );
};