import { useState, ChangeEvent } from 'react';
import { Holiday } from '../types/holiday.types';
import { isHoliday24Hours } from '../utils/holiday.utils';

interface HolidayModalState {
    isOpen: boolean;
    holiday: Holiday | null;
    errors: {
        page: boolean;
        name: string;
        startDate: string;
        channel: string;
    };
}

interface UseHolidayModalReturn {
    modalState: HolidayModalState;
    openModal: (holiday: Holiday) => void;
    closeModal: () => void;
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleTimeChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleCheckboxChange: (e: any) => void;
    handleToggle24Hours: (e: any) => void;
    is24HoursChecked: boolean;
    validateAndSubmit: () => Promise<boolean>;
}

const initialErrors = {
	page: false,
	name: '',
	startDate: '',
	channel: ''
};

export const useHolidayModal = (): UseHolidayModalReturn => {
	const [modalState, setModalState] = useState<HolidayModalState>({
		isOpen: false,
		holiday: null,
		errors: initialErrors
	});
	const [is24HoursChecked, setIs24HoursChecked] = useState(false);

	const openModal = (holiday: Holiday) => {
		setModalState({
			isOpen: true,
			holiday,
			errors: initialErrors
		});
		setIs24HoursChecked(isHoliday24Hours(holiday));
	};

	const closeModal = () => {
		setModalState({
			isOpen: false,
			holiday: null,
			errors: initialErrors
		});
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (!modalState.holiday) return;

		setModalState({
			...modalState,
			holiday: {
				...modalState.holiday,
				[e.target.name]: e.target.value
			}
		});
	};

	const handleTimeChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (!modalState.holiday) return;

		const { id, value } = e.target;
		const { holiday } = modalState;

		let updatedDate = '';
		if (id.includes('start')) {
			updatedDate = id === 'startDate' 
				? `${value}T${holiday.startDate.split('T')[1]}`
				: `${holiday.startDate.split('T')[0]}T${value}`;
			setModalState({
				...modalState,
				holiday: { ...holiday, startDate: updatedDate }
			});
		} else {
			updatedDate = id === 'endDate'
				? `${value}T${holiday.endDate.split('T')[1]}`
				: `${holiday.endDate.split('T')[0]}T${value}`;
			setModalState({
				...modalState,
				holiday: { ...holiday, endDate: updatedDate }
			});
		}
	};

	const handleCheckboxChange = (e: any) => {
		if (!modalState.holiday) return;

		const { checked, value } = e.target;
		const currentTypes = JSON.parse(modalState.holiday.connectionTypes || '[]');
        
		const updatedTypes = checked
			? [...currentTypes, value]
			: currentTypes.filter((type: string) => type !== value);

		setModalState({
			...modalState,
			holiday: {
				...modalState.holiday,
				connectionTypes: JSON.stringify(updatedTypes)
			},
			errors: {
				...modalState.errors,
				channel: ''
			}
		});
	};

	const handleToggle24Hours = (e: any) => {
		if (!modalState.holiday) return;

		const checked = e.target.checked;
		setIs24HoursChecked(checked);

		const startDate = modalState.holiday.startDate.split('T')[0];
		const endDate = modalState.holiday.endDate.split('T')[0];

		setModalState({
			...modalState,
			holiday: {
				...modalState.holiday,
				startDate: `${startDate}T${checked ? '00:00' : '00:00'}`,
				endDate: `${endDate}T${checked ? '23:59' : '00:01'}`
			}
		});
	};

	const validateAndSubmit = async (): Promise<boolean> => {

		if (!modalState.holiday) return false;

		const errors = {
			page: false,
			name: '',
			startDate: '',
			channel: ''
		};

		if (!modalState.holiday.name) {
			errors.name = 'Name cannot be left blank';
			errors.page = true;
		}

		if (modalState.holiday.startDate > modalState.holiday.endDate) {
			errors.startDate = 'Start date cannot be after end date';
			errors.page = true;
		}

		const channels = JSON.parse(modalState.holiday.connectionTypes || '[]');
		if (!channels.length) {
			errors.channel = 'At least one channel must be selected';
			errors.page = true;
		}

		setModalState({
			...modalState,
			errors
		});

		return !errors.page;
	};

	return {
		modalState,
		openModal,
		closeModal,
		handleChange,
		handleTimeChange,
		handleCheckboxChange,
		handleToggle24Hours,
		is24HoursChecked,
		validateAndSubmit
	};
};