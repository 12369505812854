import { FC, ChangeEvent } from "react";
import { Box } from "@twilio-paste/core/box";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
} from "@twilio-paste/core/modal";
import { Button } from "@twilio-paste/core/button";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Heading } from "@twilio-paste/core/heading";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { Alert, HelpText } from "@twilio-paste/core";
import { Holiday } from "../types/holiday.types";
import { DateTimeSelection } from "./DateTimeSelection";

interface HolidaysModalProps {
  isOpen: boolean;
  modalHeadingId: string;
  holiday: Holiday | null;
  errors: {
    page: boolean;
    name: string;
    startDate: string;
    channel: string;
  };
  onClose: () => void;
  onSubmit: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onTimeChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onCheckboxChange: (e: any) => void;
  onToggle24Hours: (e: any) => void;
  is24HoursChecked: boolean;
}

export const HolidaysModal: FC<HolidaysModalProps> = ({
  isOpen,
  modalHeadingId,
  holiday,
  errors,
  onClose,
  onSubmit,
  onChange,
  onTimeChange,
  onCheckboxChange,
  onToggle24Hours,
  is24HoursChecked,
}) => {
  if (!holiday) return null;

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      size="wide"
      ariaLabelledby={modalHeadingId}
    >
      <ModalHeader>
        <ModalHeading as="h2" id={modalHeadingId}>
          Configure Holiday
        </ModalHeading>
      </ModalHeader>

      <ModalBody>
        {errors.page && (
          <Box paddingY="space60">
            <Alert variant="error">
              Validation errors have been found on this holiday, please check
              these before continuing
            </Alert>
          </Box>
        )}

        <Heading as="h4" variant="heading40">
          Please select start date and time, end date and time, label and any
          relevant notes.
        </Heading>

        <Box
          display="flex"
          flexDirection="column"
          marginY="space60"
          padding="space60"
        >
          <Checkbox
            id="24h"
            checked={is24HoursChecked}
            name="24h"
            onClick={onToggle24Hours}
          >
            24 hours?
          </Checkbox>

          {/* Date/Time Selection Section */}
          <DateTimeSelection
            label="Start Date and Time"
            dateId="startDate"
            timeId="startTime"
            dateValue={holiday.startDate.split("T")[0]}
            timeValue={
              holiday.startDate.includes(".")
                ? holiday.startDate.split("T")[1].split(".")[0]
                : holiday.startDate.split("T")[1].split("Z")[0]
            }
            onChange={onTimeChange}
          />

          <DateTimeSelection
            label="End Date and Time"
            dateId="endDate"
            timeId="endTime"
            dateValue={holiday.endDate.split("T")[0]}
            timeValue={
              holiday.endDate.includes(".")
                ? holiday.endDate.split("T")[1].split(".")[0]
                : holiday.endDate.split("T")[1].split("Z")[0]
            }
            onChange={onTimeChange}
            error={errors.startDate}
          />

          {/* Name Input Section */}
          <Box
            display="flex"
            flexDirection="row"
            marginY="space60"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label htmlFor="name">Name</Label>
            <Box display="flex" flexDirection="column">
              <Input
                type="text"
                onChange={onChange}
                id="name"
                name="name"
                value={holiday.name}
                required
                hasError={!!errors.name}
              />
              {errors.name && (
                <HelpText variant="error">{errors.name}</HelpText>
              )}
            </Box>
          </Box>

          {/* Channel Selection Section */}
          <Box
            display="flex"
            flexDirection="row"
            marginY="space60"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label htmlFor="channel">Channel</Label>
            <CheckboxGroup
              orientation="horizontal"
              name="chk"
              legend=""
              helpText=""
            >
              {["Phone", "Online", "Video"].map((channel) => (
                <Checkbox
                  key={channel}
                  id={channel}
                  checked={holiday.connectionTypes?.includes(channel)}
                  value={channel}
                  name={channel}
                  onClick={onCheckboxChange}
                >
                  {channel}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Box>
          {errors.channel && (
            <Box display="flex" justifyContent="flex-end">
              <HelpText variant="error">{errors.channel}</HelpText>
            </Box>
          )}

          {/* Notes Section */}
          <Box
            display="flex"
            flexDirection="row"
            marginY="space60"
            alignItems="center"
            justifyContent="space-between"
          >
            <Label htmlFor="notes">Notes</Label>
            <Box
              display="flex"
              justifyContent="flex-end"
              columnGap="space60"
              width="25vw"
            >
              <Input
                name="notes"
                onChange={onChange}
                type="text"
                value={holiday.notes}
              />
            </Box>
          </Box>
        </Box>
      </ModalBody>

      <ModalFooter>
        <ModalFooterActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooterActions>
      </ModalFooter>
    </Modal>
  );
};
