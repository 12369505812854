import { FC, useEffect, useState } from "react";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { Text } from "@twilio-paste/core/text";
import { Stack } from "@twilio-paste/core/stack";
import { Button } from "@twilio-paste/core/button";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Holiday } from "../types/holiday.types";
import { formatDateTime } from "../utils/timezone.utils";

interface HolidaysTableProps {
  holidays: Holiday[];
  loaded: boolean;
  userTimezone: string;
  onEdit: (id: number) => void;
  onDelete: (id: string) => void;
}

const formatDate = (
  date: string | undefined,
  timezone: string | undefined
): string => {
  if (!date) {
    console.warn("Missing date in holiday record");
    return "Date not set";
  }
  return formatDateTime(date, timezone);
};

const formatChannels = (connectionTypes: string): string => {
  try {
    const channels = JSON.parse(connectionTypes);

    if (Array.isArray(channels)) {
      return channels.join(", ");
    }

    return connectionTypes
      .replace(/[[\]"\\\s]/g, "")
      .split(",")
      .filter(Boolean)
      .join(", ");
  } catch (error) {
    console.error("Error formatting channels:", error);
    return connectionTypes;
  }
};

export const HolidaysTable: FC<HolidaysTableProps> = ({
  holidays,
  loaded,
  userTimezone,
  onEdit,
  onDelete,
}) => {
  if (!loaded || !holidays.length) {
    return (
      <Table>
        <THead>
          <Tr>
            <Th>Actions</Th>
            <Th>Start</Th>
            <Th>End</Th>
            <Th>Name</Th>
            <Th>Channel(s)</Th>
            <Th>Notes</Th>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td colSpan={6}>No data to display</Td>
          </Tr>
        </TBody>
      </Table>
    );
  }

  return (
    <Table>
      <THead>
        <Tr>
          <Th>Actions</Th>
          <Th>Start</Th>
          <Th>End</Th>
          <Th>Name</Th>
          <Th>Channel(s)</Th>
          <Th>Notes</Th>
        </Tr>
      </THead>
      <TBody>
        {holidays.map((holiday, index) => (
          <Tr key={`holiday-${index}`}>
            <Td>
              <Stack orientation="vertical" spacing="space60">
                <Button
                  variant="secondary"
                  onClick={() => onEdit(holiday.agencyHolidayConfigId)}
                >
                  <EditIcon title="Edit Holiday" decorative={false} />
                </Button>
                <Button
                  variant="destructive"
                  onClick={() =>
                    onDelete(holiday.agencyHolidayConfigId.toString())
                  }
                >
                  <DeleteIcon title="Delete Holiday" decorative={false} />
                </Button>
              </Stack>
            </Td>
            <Td>
              <Text as="p">{formatDate(holiday.startDate, userTimezone)}</Text>
            </Td>
            <Td>
              <Text as="p">{formatDate(holiday.endDate, userTimezone)}</Text>
            </Td>
            <Td>
              <Text as="p">{holiday.name}</Text>
            </Td>
            <Td>
              <Text as="p">{formatChannels(holiday.connectionTypes)}</Text>
            </Td>
            <Td>
              <Text as="p">{holiday.notes}</Text>
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};
