import { FC, Key, ReactChild, ReactFragment, ReactPortal } from "react";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";

interface ConnectionTableProps {
  filteredReportingData: any ;
  loaded: boolean;
  convertUTCToLocal: (dt: string) => string | undefined;
  userTimezone: { timezone: string };
}

export const ConnectionTable: FC<ConnectionTableProps> = ({
  filteredReportingData,
  loaded,
  convertUTCToLocal,
  userTimezone,
}) => {
  if (!loaded || !filteredReportingData) {
    return (
      <Box display="flex" marginY="space60" alignSelf="center" width="100%">
        <Table>
          <THead>
            <Tr>
              <Th>Connection SID</Th>
              <Th>Language</Th>
              <Th>Connection Type</Th>
              <Th>Service</Th>
              <Th>State</Th>
              <Th>ZIP Code</Th>
              <Th>Client Phone Number</Th>
              <Th>Dialed Phone Number</Th>
              <Th>Destination</Th>
              <Th>Email</Th>
              <Th>Is Duplicate</Th>
              <Th>Timestamp</Th>
              <Th>Campaign</Th>
              <Th>Referrer</Th>
            </Tr>
          </THead>
          <TBody>
            <Tr>
              <Td colSpan={14}>No data to display</Td>
            </Tr>
          </TBody>
        </Table>
      </Box>
    );
  }

  return (
    <Box display="flex" marginY="space60" alignSelf="center" width="100%">
      <Table aria-label="Connection data table" striped>
        <THead>
          <Tr>
            <Th>Connection SID</Th>
            <Th>Language</Th>
            <Th>Connection Type</Th>
            <Th>Service</Th>
            <Th>State</Th>
            <Th>ZIP Code</Th>
            <Th>Client Phone Number</Th>
            <Th>Dialed Phone Number</Th>
            <Th>Destination</Th>
            <Th>Email</Th>
            <Th>Is Duplicate</Th>
            <Th>Timestamp</Th>
            <Th>Campaign</Th>
            <Th>Referrer</Th>
          </Tr>
        </THead>
        <TBody>
          {filteredReportingData.map((f: { flowSid: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; language: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; connectionType: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; service: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; state: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; zipCode: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; fromPhone: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; toPhone: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; destinationPhone: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; fromEmail: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; duplicateCheckResult: number; endDateTime: any; campaign: any; referrer: any; }, rowIndex: Key | null | undefined) => (
            <Tr key={rowIndex}>
              <Td>{f.flowSid}</Td>
              <Td>{f.language}</Td>
              <Td>{f.connectionType}</Td>
              <Td>{f.service}</Td>
              <Td>{f.state}</Td>
              <Td>{f.zipCode}</Td>
              <Td>{f.fromPhone}</Td>
              <Td>{f.toPhone}</Td>
              <Td>{f.destinationPhone}</Td>
              <Td>{f.fromEmail}</Td>
              <Td>{(f.duplicateCheckResult === 0 && "No") || "Yes"}</Td>
              <Td>{convertUTCToLocal(f.endDateTime as any)} {userTimezone?.timezone}</Td>
              <Td>{f.campaign as any}</Td>
              <Td>{f.referrer as any}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </Box>
  );
};