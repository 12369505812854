import { FC, useEffect, useState, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@twilio-paste/core/box";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { Button } from "@twilio-paste/core/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { Paragraph, Radio, RadioGroup } from "@twilio-paste/core";
import { DateTime } from "luxon";
import { Holidays as NFCCHolidays } from "@ciptex/nfcc";

import { useToasterContext } from "../../hooks/useToasterContext";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { getUserTimezone } from "../../functions/getUserTimezone";
import { useHolidayModal } from "./hooks/useHolidayModal";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { HolidaysTable } from "./components/HolidaysTable";
import { HolidaysModal } from "./components/HolidaysModal";
import { DeleteHolidayModal } from "./components/HolidaysDeleteModal";
import type { Holiday } from "./types/holiday.types";
import {
  getDisplayTimezones,
  getServerTimezone,
  findTimezoneByName,
  getIANATimezone,
  formatDateTime,
} from "./utils/timezone.utils";
import { timezones } from "../../constants";

const processApiHoliday = (holiday: any) => {
  const ensureISOString = (dateStr: string | undefined) => {
    if (!dateStr) return new Date().toISOString();
    return dateStr.replace(" ", "T").split(".")[0] + "Z";
  };

  return {
    agencyHolidayConfigId: holiday.agencyHolidayConfigId || -1,
    startDate: ensureISOString(holiday.dateStart),
    endDate: ensureISOString(holiday.dateEnd),
    name: holiday.name || "",
    notes: holiday.notes || "",
    connectionTypes:
      typeof holiday.connectionTypes === "string"
        ? holiday.connectionTypes
        : JSON.stringify(holiday.connectionTypes || []),
  };
};

export const HolidaysPage: FC = () => {
  const { toaster } = useToasterContext();
  const navigate = useNavigate();
  const { agencyId } = useParams();
  const { appState } = useAppState();
  const { fetchUserTimezone } = getUserTimezone();
  const editModalHeadingID = useUID();
  const deleteModalHeadingID = useUID();
  const [displayTimezone, setDisplayTimezone] = useState<"me" | "agency">("me");

  const {
    getHolidays,
    getAgency,
    listTimezones,
    updateAgency,
    createHoliday,
    updateHoliday,
    deleteHoliday,
  } = useNFCCContext();

  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [tz, setTz] = useState<string>();
  const [deleteIndex, setDeleteIndex] = useState<string>("");
  const [userTimezone, setUserTimezone] = useState<any>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    modalState,
    openModal,
    closeModal,
    handleChange,
    handleTimeChange,
    handleCheckboxChange,
    handleToggle24Hours,
    is24HoursChecked,
    validateAndSubmit,
  } = useHolidayModal();

  const fetchHolidays = async () => {
    try {
      const agId = parseInt(agencyId ?? "0") || appState.agencyId;
      const apiHolidays: NFCCHolidays = await getHolidays(agId);
      const agency = await getAgency(agId);

      const agencyTimezone = agency.timezone || "EST";
      console.log("Setting timezone:", agencyTimezone);
      setTz(agencyTimezone);

      if (apiHolidays.length > 0) {
        console.log("Raw API holidays:", apiHolidays);

        const processedHolidays = apiHolidays.map(processApiHoliday);

        console.log("Processed holidays:", processedHolidays);

        setHolidays(processedHolidays);
        setLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
      toaster.push({
        message: "Could not retrieve holidays",
        variant: "error",
        dismissAfter: 4000,
      });
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        const timezone = await fetchUserTimezone();
        console.log("Fetched user timezone:", timezone);
        setUserTimezone(timezone);
        await fetchHolidays();
      } catch (error) {
        console.error("Error initializing data:", error);
        setUserTimezone({ timezone: "EST" });
      }
    };

    initializeData();
  }, []);

  const handleAddHoliday = () => {
    const newHoliday: Holiday = {
      agencyHolidayConfigId: -Math.floor(Math.random() * 1000 + 1),
      startDate: formatDateTime(
        new Date().toISOString().substring(0, 16),
        displayTimezone === "me" ? userTimezone.timezone : tz,
        "yyyy-MM-dd HH:mm"
      ).replace(' ','T').split(' ')[0],
      endDate: formatDateTime(
        new Date().toISOString().substring(0, 16),
        displayTimezone === "me" ? userTimezone.timezone : tz,
        "yyyy-MM-dd HH:mm"
      ).replace(' ','T').split(' ')[0],
      name: "",
      notes: "",
      connectionTypes: "[]",
    };
    openModal(newHoliday);
  };

  const handleEditHoliday = (id: number) => {
    let holidayToEdit = {...holidays.find((h) => h.agencyHolidayConfigId === id)};
    if (holidayToEdit) {
      //todo transform to display tz
      holidayToEdit.startDate = formatDateTime(
        holidayToEdit.startDate ?? '',
        displayTimezone === "me" ? userTimezone.timezone : tz,
        "yyyy-MM-dd HH:mm"
      ).replace(' ','T').split(' ')[0];
      holidayToEdit.endDate = formatDateTime(
        holidayToEdit.endDate ?? '',
        displayTimezone === "me" ? userTimezone.timezone : tz,
        "yyyy-MM-dd HH:mm"
      ).replace(' ','T').split(' ')[0];

      openModal(holidayToEdit as Holiday);
    }
  };

  const handleDeleteHoliday = (id: string) => {
    setDeleteIndex(id);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const agId = parseInt(agencyId ?? "0") || appState.agencyId;

      if (deleteIndex.includes("#")) {
        const [id1, id2] = deleteIndex.split("#").map(Number);
        await deleteHoliday(agId, id1);
        await deleteHoliday(agId, id2);
      } else {
        await deleteHoliday(agId, parseInt(deleteIndex));
      }

      setHolidays(
        holidays.filter(
          (h) => h.agencyHolidayConfigId.toString() !== deleteIndex
        )
      );
      setDeleteIndex("");
      setDeleteModalOpen(false);

      toaster.push({
        message: "Holiday deleted successfully",
        variant: "success",
        dismissAfter: 4000,
      });
    } catch (error) {
      console.error("Error deleting holiday:", error);
      toaster.push({
        message: "Failed to delete holiday",
        variant: "error",
        dismissAfter: 4000,
      });
    }
  };

  const handleModalSubmit = async () => {
    const isValid = await validateAndSubmit();
    if (isValid && modalState.holiday) {
      const updatedHolidays = [...holidays];
      const index = updatedHolidays.findIndex(
        (h) =>
          h.agencyHolidayConfigId === modalState.holiday?.agencyHolidayConfigId
      );
      //convert the datetimes from agency's tz to utc
      // convert datetimes to UTC, write to DB
      const h = modalState.holiday;
      let tz_ = displayTimezone === "me" ? userTimezone.timezone : tz;

      const ianaTimezone = getIANATimezone(tz_ ?? "");

      const startDate = DateTime.fromISO(h.startDate + ":00.00000", {
        zone: ianaTimezone,
      }).toUTC();

      const endDate = DateTime.fromISO(h.endDate + ":00.00000", {
        zone: ianaTimezone,
      }).toUTC();

      if (index >= 0) {
        updatedHolidays[index] = {
          ...modalState.holiday,
          modified: true,
          startDate: startDate.toISO(),
          endDate: endDate.toISO(),
        };
      } else {
        updatedHolidays.push({
          ...modalState.holiday,
          modified: true,
          startDate: startDate.toISO(),
          endDate: endDate.toISO(),
        });
      }

      setHolidays(updatedHolidays);
      closeModal();
      setLoaded(true);
    }
  };

  const handleChangeTz = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setTz(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const agId = parseInt(agencyId ?? "0") || appState.agencyId;
      const agency = await getAgency(agId);
      const serverTimezones = await listTimezones();

      if (!tz) {
        throw new Error("No timezone selected");
      }

      const selectedTimezone = findTimezoneByName(tz, timezones);
      if (!selectedTimezone) {
        throw new Error(`Could not find timezone info for ${tz}`);
      }

      const serverTimezone = getServerTimezone(
        selectedTimezone,
        serverTimezones
      );
      if (!serverTimezone) {
        throw new Error(
          `Could not match timezone ${selectedTimezone.tag} with server timezones`
        );
      }

      await updateAgency(agId, {
        ...agency,
        timezoneId: serverTimezone.timezoneId,
      });

      for (const holiday of holidays) {
        if (holiday.modified || holiday.agencyHolidayConfigId < 0) {
          const ianaTimezone = getIANATimezone(selectedTimezone.name);

          const startDate = DateTime.fromISO(holiday.startDate, {
            zone: ianaTimezone,
          }).toUTC();

          const endDate = DateTime.fromISO(holiday.endDate, {
            zone: ianaTimezone,
          }).toUTC();

          const holidayData = {
            agencyId: agId,
            dateStart: startDate.toISO(),
            dateEnd: endDate.toISO(),
            notes: holiday.notes,
            name: holiday.name,
            connectionTypes: JSON.parse(holiday.connectionTypes),
          };

          if (holiday.agencyHolidayConfigId < 0) {
            await createHoliday(agId, holidayData);
          } else {
            await updateHoliday(agId, holiday.agencyHolidayConfigId, {
              ...holidayData,
              agencyHolidayConfigId: holiday.agencyHolidayConfigId,
            });
          }
        }
      }

      toaster.push({
        message: "Holidays updated successfully!",
        variant: "success",
        dismissAfter: 4000,
      });

      if (appState.role === "nfcc_admin") {
        navigate(`/agencies/${agencyId}`);
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Holiday update error:", error);
      toaster.push({
        message:
          error instanceof Error ? error.message : "Could not update holidays",
        variant: "error",
        dismissAfter: 4000,
      });
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap="space40" width="50%">
        <HeaderTitleText titleText="Manage Holidays" />
        <Label htmlFor="timezone">Schedule Timezone</Label>
        <Select
          name="timezone"
          id="timezone"
          value={tz}
          onChange={handleChangeTz}
        >
          {getDisplayTimezones(timezones, userTimezone?.timezone)
            .filter((tz: any) => !tz.isDaylightSavings)
            .map((timezone) => (
              <Option key={`${timezone.tag}`} value={timezone.tag}>
                {timezone.name.replace("_", " ")} ({timezone.tag})
              </Option>
            ))}
        </Select>

        <Paragraph marginBottom="space0">
          Please update with any dates that your agency does not wish to receive
          connections. To add a new holiday, click the plus button.
        </Paragraph>

        <RadioGroup
          name="radio-group"
          legend="Show datetimes as"
          onChange={(value: string) => {
            setDisplayTimezone(value as "me" | "agency");
          }}
          value={displayTimezone}
        >
          <Radio
            id={useUID()}
            value={"me" as "me" | "agency"}
            name="radio-group"
          >
            {"My timezone (" + (userTimezone?.timezone ?? "none set") + ")"}
          </Radio>
          <Radio
            id={useUID()}
            value={"agency" as "me" | "agency"}
            name="radio-group"
          >
            {"Agency's timezone (" + (tz ?? "none set") + ")"}
          </Radio>
        </RadioGroup>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button variant="primary" onClick={handleAddHoliday}>
          <PlusIcon decorative={false} title="Add new holiday" />
          Add New Holiday
        </Button>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box marginY="space60">
          <HolidaysTable
            key={"holidays" + holidays.length}
            holidays={holidays ?? []}
            loaded={loaded}
            userTimezone={
              displayTimezone === "me" ? userTimezone?.timezone : tz
            }
            onEdit={handleEditHoliday}
            onDelete={handleDeleteHoliday}
          />

          <Box
            display="flex"
            justifyContent="flex-end"
            position="relative"
            marginY="space60"
          >
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Box>
        </Box>
      </form>

      <HolidaysModal
        isOpen={modalState.isOpen}
        modalHeadingId={editModalHeadingID}
        holiday={modalState.holiday}
        errors={modalState.errors}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
        onChange={handleChange}
        onTimeChange={handleTimeChange}
        onCheckboxChange={handleCheckboxChange}
        onToggle24Hours={handleToggle24Hours}
        is24HoursChecked={is24HoursChecked}
      />

      <DeleteHolidayModal
        isOpen={deleteModalOpen}
        modalHeadingId={deleteModalHeadingID}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};
