import { DateTime } from 'luxon';

export const convertTimeStringToLocal = (
	timeString: string | undefined,
): string => {
	if (!timeString) return '';

	try {
		const milliseconds = timeString.split('.')[1];
		const timeWithoutMilliseconds = timeString.split('.')[0];

		const dateTime = DateTime.fromFormat(timeWithoutMilliseconds, "HH:mm:ss", {
			zone: 'utc'
		});

		const localDateTime = dateTime.toLocal();

		if (!dateTime.isValid) {
			console.error('Invalid time conversion:', {
				input: timeString,
				invalidReason: dateTime.invalidReason
			});
			return timeString;
		}

		const result = `${localDateTime.toFormat("HH:mm:ss")}.${milliseconds}`;

		return result;
	} catch (error) {
		console.error('Error converting time:', {
			input: timeString,
			error: error instanceof Error ? error.message : String(error)
		});
		return timeString;
	}
};