import { FC, ChangeEvent } from 'react';
import { Box } from "@twilio-paste/core/box";
import { Label } from "@twilio-paste/core/label";
import { DatePicker } from "@twilio-paste/core/date-picker";
import { TimePicker } from "@twilio-paste/core/time-picker";
import { HelpText } from "@twilio-paste/core";

interface DateTimeSelectionProps {
    label: string;
    dateId: string;
    timeId: string;
    dateValue: string;
    timeValue: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    error?: string;
}

export const DateTimeSelection: FC<DateTimeSelectionProps> = ({
	label,
	dateId,
	timeId,
	dateValue,
	timeValue,
	onChange,
	error
}) => (
	<Box
		display="flex"
		flexDirection="row"
		marginY="space60"
		justifyContent="space-between"
		alignItems="center"
	>
		<Box display="flex">
			<Label htmlFor={dateId}>{label}</Label>
		</Box>
		<Box display="flex" flexDirection="column">
			<Box
				display="flex"
				justifyContent="flex-end"
				columnGap="space60"
			>
				<DatePicker
					onChange={onChange}
					required
					id={dateId}
					value={dateValue}
					enterKeyHint={undefined}
				/>
				<TimePicker
					required
					id={timeId}
					name={timeId}
					value={timeValue}
					onChange={onChange}
					enterKeyHint={undefined}
				/>
			</Box>
			{error && (
				<Box display="flex" justifyContent="flex-end">
					<HelpText variant="error">{error}</HelpText>
				</Box>
			)}
		</Box>
	</Box>
);