import { FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Audits, User, Users } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { Button } from "@twilio-paste/core/button";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useParams } from "react-router-dom";
import { Scroller } from "../Scroller/Scroller";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { timezones } from "../../constants";
import { convertTimeStringToLocal } from "../../functions/generic";

export const ChangeHistoryTable: FC = () => {
  const [audits, setAudits] = useState<Audits>();
  const [loaded, setLoaded] = useState<boolean>();
  const { agencyId } = useParams();
  const [tz, setTz] = useState<string>();

  const { listAgencyAudits, listUsersEmailFilter, getUser } = useNFCCContext();
  const { appState } = useAppState();
  const { toaster } = useToasterContext();

  const getDate = () => {
    const today = DateTime.local();
    const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
    return todayDate;
  };

  const tzs = timezones

  const exportTableData = () => {
    if (audits) {
      const csvData = [
        [
          "Date and Time",
          "Agency ID",
          "Agency Name",
          "Entity Type",
          "Identifier",
          "Field Name",
          "Original Value",
          "New Value",
          "Changed By",
          "Changed By Type",
        ],
        ...audits.map(
          ({
            changeDateTime,
            netsuiteRef,
            agencyName,
            entityType,
            identifier,
            fieldName,
            originalValue,
            newValue,
            changedBy,
            changedByType,
          }) => [
            convertTimeStringToLocal(changeDateTime),
            netsuiteRef,
            agencyName,
            entityType,
            identifier,
            fieldName,
            originalValue,
            newValue,
            changedBy,
            changedByType,
          ],
        ),
      ];

      const csv = csvData.map((row) => row.join(",")).join("\n");
      const csvBlob = new Blob([csv], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = `audits-${getDate()}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  function getMonthFromString(mon: string) {
    let s = (new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1).toString();
    if (s.length === 1) {
      s = "0" + s;
    }
    return s;
  }

  useEffect(() => {
    (async () => {
      try {
        let auditData: Audits = {} as Audits;
        const users: Users = await listUsersEmailFilter(
          encodeURI(appState.email),
        );
        const u: User = await getUser(users[0].userId ?? 0);
        const tz1 = (u.timezone as any)?.timezone ?? "EST";
        const tz = tzs.find((t) => t.name === tz1);
        setTz(tz?.tag);

        if (!agencyId) {
          // current user is an agency user, render their own data
          const data: Audits = await listAgencyAudits(appState.agencyId);
          setAudits(data);
          auditData = data;
          if (data.length > 0) {
            setLoaded(true);
          }
        } else if (appState.role === "nfcc_admin") {
          // NFCC user, render for the agency from the url
          const data: Audits = await listAgencyAudits(
            parseInt(agencyId ?? "0"),
          );
          setAudits(data);
          auditData = data;
          if (data.length > 0) {
            setLoaded(true);
          }

          // Toast for when no change history for this agency
          if ((data as any).error_message) {
            console.error("no data");
            toaster.push({
              message:
                "Currently there are is no change history for this agency",
              variant: "neutral",
              dismissAfter: 4000,
            });
          }
        }

        if (auditData) {
          const auditsNew: Audits = [];
          // For each in data do new dateTime and setZone
          for (const audit of auditData) {
            const oldDT = audit.changeDateTime;
            const oldISO =
              oldDT?.split(" ")[2] +
              "-" +
              getMonthFromString(oldDT?.split(" ")[1] ?? "") +
              "-" +
              oldDT?.split(" ")[0] +
              "T" +
              oldDT?.split(" ")[3] +
              ":00+00:00";
            const changeDateTime = DateTime.fromISO(oldISO, {
              zone: tz?.tag,
            });
            audit.changeDateTime = changeDateTime.toFormat("dd MMM yyyy HH:mm");
            auditsNew.push(audit);
          }
          setAudits(auditsNew);
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve audit data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  return (
    <Box width="100%">
      <Scroller />
      <HeaderTitleText titleText="Configuration Change History" />
      <Box display="flex" justifyContent="flex-end" marginY="space60">
        <Button variant="secondary" onClick={exportTableData}>
          Download CSV
          <DownloadIcon decorative={false} title="download csv icon" />
        </Button>
      </Box>

      <Table aria-label="Audits table" striped>
        <THead>
          <Tr>
            <Th>Date and Time</Th>
            <Th>Entity Type</Th>
            <Th>Identifier</Th>
            <Th>Field Name</Th>
            <Th>Original Value</Th>
            <Th>New Value</Th>
            <Th>Changed By</Th>
            <Th>Changed By Type</Th>
          </Tr>
        </THead>
        {loaded ? (
          <TBody>
            {audits && loaded ? (
              audits &&
              audits.map((audit: any, rowIndex: number) => {
                if (audit.originalValue && typeof audit.originalValue === 'string' && audit.originalValue.includes('endTime')) {
                    
                    try {
                        const timeData = JSON.parse(audit.originalValue);
                        
                        timeData.forEach((item: any) => {
                            item.startTime = convertTimeStringToLocal(item.startTime);
                            item.endTime = convertTimeStringToLocal(item.endTime);
                        });

                        audit.originalValue = JSON.stringify(timeData);
                    } catch (error) {
                        console.error('Error processing original value:', error);
                    }
                }
            
                if (audit.newValue && typeof audit.newValue === 'string' && audit.newValue.includes('endTime')) {
                    
                    try {
                        const timeData = JSON.parse(audit.newValue);
                        
                        timeData.forEach((item: any) => {
                            item.startTime = convertTimeStringToLocal(item.startTime);
                            item.endTime = convertTimeStringToLocal(item.endTime);
                        });
                        
                        audit.newValue = JSON.stringify(timeData);
                    } catch (error) {
                        console.error('Error processing new value:', error);
                    }
                }
            
                return (
                    <Tr key={rowIndex}>
                        <Td>{audit.changeDateTime}</Td>
                        <Td>{audit.entityType}</Td>
                        <Td>{audit.identifier}</Td>
                        <Td>{audit.fieldName}</Td>
                        <Td>{audit.originalValue}</Td>
                        <Td>{audit.newValue}</Td>
                        <Td>{audit.changedBy}</Td>
                        <Td>{audit.changedByType}</Td>
                    </Tr>
                );
            })
            ) : (
              <Tr>
                <Td colSpan={8}>No data to display</Td>
              </Tr>
            )}
          </TBody>
        ) : (
          <TBody>
            <TableSkeletonLoader numberOfTr={4} numberOfTd={8} />
          </TBody>
        )}
      </Table>
    </Box>
  );
};
