import type { Holiday } from '../types/holiday.types';

export const isHoliday24Hours = (holiday: Holiday): boolean => {
	try {
		const startTime = holiday.startDate.split('T')[1];
		const endTime = holiday.endDate.split('T')[1];
		return startTime === '00:00' && endTime === '23:59';
	} catch {
		return false;
	}
};