import { FC } from 'react';
import { Box } from "@twilio-paste/core/box";
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from "@twilio-paste/core/modal";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";

interface DeleteHolidayModalProps {
    isOpen: boolean;
    modalHeadingId: string;
    onClose: () => void;
    onConfirm: () => void;
}

export const DeleteHolidayModal: FC<DeleteHolidayModalProps> = ({
	isOpen,
	modalHeadingId,
	onClose,
	onConfirm
}) => (
	<Modal
		isOpen={isOpen}
		onDismiss={onClose}
		size="default"
		ariaLabelledby={modalHeadingId}
	>
		<ModalHeader>
			<ModalHeading as="h2" id={modalHeadingId}>
                Delete Holiday
			</ModalHeading>
		</ModalHeader>

		<ModalBody>
			<Box marginY="space60">
				<Text as="p">Are you sure want to delete this holiday?</Text>
			</Box>
		</ModalBody>

		<ModalFooter>
			<ModalFooterActions>
				<Button variant="secondary" onClick={onClose}>
                    Cancel
				</Button>
				<Button variant="destructive" onClick={onConfirm}>
                    Delete Holiday
				</Button>
			</ModalFooterActions>
		</ModalFooter>
	</Modal>
);