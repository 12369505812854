import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Alert } from "@twilio-paste/core/alert";
import { HeaderTitleText } from "../../HeaderTitleText/HeaderTitleText";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";


interface ConnectionHeaderProps {
  onGetReport: () => void;
  onExportData: () => void;
}

export const ConnectionHeader: FC<ConnectionHeaderProps> = ({
  onGetReport,
  onExportData,
}) => {
  return (
    <>
      <Box marginY="space60">
        <Alert variant="neutral">
          Data is only available for the last 90 days through the portal. For data prior to this period, please reach out to mstout@nfcc.org
        </Alert>
      </Box>
      
      <HeaderTitleText titleText="Connection Insights" />

      <Box display="flex" justifyContent="flex-end" marginY="space60">
        <Button variant="secondary" onClick={onGetReport}>
          Show Report
        </Button>
      </Box>

      <Box display="flex" justifyContent="flex-end" marginY="space60">
        <Button variant="secondary" onClick={onExportData}>
          Download CSV
          <DownloadIcon decorative={false} title="download csv icon" />
        </Button>
      </Box>
    </>
  );
};