import { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@twilio-paste/core/box";
import { formatReturnDate } from "@twilio-paste/core/date-picker";
import { DateTime } from "luxon";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { getUserTimezone } from "../../functions/getUserTimezone";
import { Scroller } from "../Scroller/Scroller";
import {
  Agency,
  Services,
  ConnectionInsightsReport,
  User,
  Users,
} from "@ciptex/nfcc";

import { ConnectionHeader } from "./components/ConnectionHeader";
import { ConnectionFilters } from "./components/ConnectionFilters";
import { ConnectionChart } from "./components/ConnectionChart";
import { ConnectionTable } from "./components/ConnectionTable";
import { sortConnectionInsightsByTimestamp } from "./utils/sortConnectionInsights";

interface FilterState {
  service: string;
  connectionType: string;
  language: string;
}

const tzs = [
  { name: "EST", tag: "America/New_York" },
  { name: "EDT", tag: "America/New_York" },
  { name: "CST", tag: "America/Chicago" },
  { name: "CDT", tag: "America/Chicago" },
  { name: "MST", tag: "America/Denver" },
  { name: "MDT", tag: "America/Denver" },
  { name: "PST", tag: "America/Los_Angeles" },
  { name: "PDT", tag: "America/Los_Angeles" },
  { name: "HST", tag: "Pacific/Honolulu" },
  { name: "HSDT", tag: "Pacific/Honolulu" },
  { name: "AKST", tag: "America/Anchorage" },
  { name: "AKDT", tag: "America/Anchorage" },
  { name: "AST", tag: "America/Halifax" },
  { name: "ADT", tag: "America/Halifax" },
  { name: "EGST", tag: "Europe/London" },
  { name: "EGT", tag: "Europe/London" },
  { name: "GMT", tag: "Europe/London" },
  { name: "NST", tag: "America/St_Johns" },
  { name: "NSDT", tag: "America/St_Johns" },
  { name: "PMDT", tag: "America/Miquelon" },
  { name: "PMST", tag: "America/Miquelon" },
  { name: "WGST", tag: "America/Godthab" },
  { name: "WGT", tag: "America/Godthab" }
];

export const ConnectionInsightsPage: FC = () => {
  const { agencyId } = useParams();
  const [agency, setAgency] = useState<Agency>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [services, setServices] = useState<Services>();
  const [tz, setTz] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [userTimezone, setUserTimezone] = useState<any>();
  const { fetchUserTimezone } = getUserTimezone();

  // In your component, replace the individual filter states with:
  const [filterState, setFilterState] = useState<FilterState>({
    service: "All Services",
    connectionType: "All Connection Types",
    language: "All Languages"
  });

  const {
    getAgency,
    listServices,
    getInsightsForAgency,
    getInsightsReportForAgency,
    listUsersEmailFilter,
    getUser
  } = useNFCCContext();
  const { appState } = useAppState();
  const { toaster } = useToasterContext();

  // State for filters
  const [servicesFilter, setServicesFilter] = useState<string[]>();
  const [connectionTypesFilter] = useState<string[]>(["Phone", "Online", "Video"]);
  const [languagesFilter] = useState<string[]>(["English", "Spanish"]);
  const [servicesFilterValue, setServicesFilterValue] = useState<string>("All Services");
  const [connectionTypesFilterValue, setConnectionTypesFilterValue] = useState<string>("All Connection Types");
  const [languagesFilterValue, setLanguagesFilterValue] = useState<string>("All Languages");
  const [dateError, setDateError] = useState<string>("");
  const [preDateError, setPreDateError] = useState<string>("");
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  // Data states
  const [filteredReportingData, setFilteredReportingData] = useState<any>();
  const [reportData, setReportData] = useState<ConnectionInsightsReport | null>(null);

  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        const timezone = await fetchUserTimezone();
        setUserTimezone(timezone);
      } catch (error) {
        console.error('Error fetching user timezone:', error);
        setUserTimezone({ timezone: 'EST' });
      }
    };
    fetchTimezone();
  }, []);

  const convertUTCToLocal = (dt: string) => {
    if (!dt) return;
    try {
      const dateTime = DateTime.fromFormat(dt, 'MM/dd/yyyy HH:mm', { zone: 'UTC' });
      if (!dateTime.isValid) {
        console.error('Invalid datetime parsing:', dateTime.invalidReason);
        return dt;
      }
      const tzCode = userTimezone?.timezone ?? 'EST';
      const localDateTime = dateTime.setZone(tzCode);
      return localDateTime.toFormat('MM/dd/yyyy HH:mm');
    } catch (error) {
      console.error('Error converting timezone:', error);
      return dt;
    }
  };

  const handleFilterChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target;

    // Update the filter state
    setFilterState(prev => ({
      ...prev,
      [name === "service" ? "service" :
        name === "connectionType" ? "connectionType" :
          "language"]: value
    }));
  };

  const handleDateChange = (name: string, val: string, format: string) => {
    const d = formatReturnDate(val, format);
    if (name === "startDatePicker") {
      setStartDate(d);
    } else {
      setEndDate(d);
    }
  };

  const getReport = async () => {
    const e = new Date();
    const d = new Date();
    e.setDate(e.getDate() - 7);

    const report = await getInsightsReportForAgency(
      agency?.agencyId ?? 0,
      startDate ?? d.toISOString().substring(0, 10),
      endDate ?? e.toISOString().substring(0, 10),
      undefined,
      undefined
    );
    setReportData(report);
  };

  const handleNoData = () => {
    setLoaded(false);
    setFilteredReportingData(undefined);
    toaster.push({
      message: "No data found for this range",
      variant: "error",
      dismissAfter: 4000
    });
  };

  const applyFilters = async () => {
    if (!agency || !startDate || !endDate) return;

    if (startDate > endDate) {
      setDateError("End date cannot be before start date");
      setPreDateError("Please fix error");
      return;
    }

    setLoading(true);
    setDateError("");
    setPreDateError("");

    const startDateUTC = `${startDate}T00:00:00+00:00`;
    const endDateUTC = `${endDate}T23:59:59+00:00`;

    const startDateLocal = DateTime.fromISO(startDateUTC, { zone: tz });
    const endDateLocal = DateTime.fromISO(endDateUTC, { zone: tz });

    try {
      const data = await getInsightsForAgency(
        agency.agencyId ?? 0,
        startDateLocal,
        endDateLocal
      );

      let filteredCCIs = Array.isArray(data) ? data : [];

      if (filteredCCIs.length > 0) {
        // Apply service filter
        if (filterState.service !== "All Services") {
          filteredCCIs = filteredCCIs.filter(s => s.service === filterState.service);
        }

        // Apply language filter
        if (filterState.language !== "All Languages") {
          filteredCCIs = filteredCCIs.filter(s => s.language === filterState.language);
        }

        // Apply connection type filter
        if (filterState.connectionType !== "All Connection Types") {
          filteredCCIs = filteredCCIs.filter(s => s.connectionType === filterState.connectionType);
        }

        if (filteredCCIs.length > 0) {
          setFilteredReportingData(sortConnectionInsightsByTimestamp(filteredCCIs));
          setLoaded(true);

          // Update report if needed
          if (reportData && agency?.agencyId) {
            const report = await getInsightsReportForAgency(
              agency.agencyId,
              startDate,
              endDate,
              filterState.service !== "All Services" ? filterState.service : undefined,
              filterState.language !== "All Languages" ? filterState.language : undefined
            );
            setReportData(report);
          }
        } else {
          handleNoData();
        }
      } else {
        handleNoData();
      }
    } catch (error) {
      console.error(error);
      toaster.push({
        message: "Error applying filters",
        variant: "error",
        dismissAfter: 4000
      });
    } finally {
      setLoading(false);
    }
  };

  const getDate = () => {
    return DateTime.local().toFormat("dd-MM-yyyy-HH-mm-ss");
  };

  const exportTableData = () => {
    if (!filteredReportingData) return;

    const csvData = [
      [
        "Connection SID",
        "Language",
        "Connection Type",
        "Service",
        "State",
        "ZIP Code",
        "Client Phone Number",
        "Dialed Phone Number",
        "Destination",
        "Email",
        "Is Duplicate",
        "Timestamp",
        "Campaign",
        "Referrer"
      ],
      ...filteredReportingData.map(({
        flowSid,
        language,
        connectionType,
        service,
        state,
        zipCode,
        fromPhone,
        toPhone,
        destinationPhone,
        fromEmail,
        duplicateCheckResult,
        endDateTime,
        campaign,
        referrer
      }: any) => {
        const convertedTime = convertUTCToLocal(endDateTime);
        return [
          flowSid,
          language,
          connectionType,
          service,
          state,
          zipCode,
          fromPhone,
          toPhone,
          destinationPhone,
          fromEmail,
          duplicateCheckResult,
          convertedTime,
          campaign,
          referrer
        ];
      })
    ];

    const csv = csvData.map(row => row.join(",")).join("\n");
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = `connection-insights-${getDate()}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const users: Users = await listUsersEmailFilter(encodeURI(appState.email));
        const u: User = await getUser(users[0].userId ?? 0);
        const tz1 = (u.timezone as any)?.timezone ?? "EST";
        const tz = tzs.find(t => t.name === tz1);
        setTz(tz?.tag);

        const agId = agencyId ? parseInt(agencyId) : appState.agencyId;
        const data: Agency = await getAgency(agId);
        setAgency(data);

        const s = await listServices();
        setServices(s);
        setServicesFilter(s.map(({ name }) => name ?? ""));

        const d = new Date();
        const endDate = d.toISOString().substring(0, 10);
        setEndDate(endDate);

        d.setDate(d.getDate() - 7);
        const startDate = d.toISOString().substring(0, 10);
        setStartDate(startDate);

        const insights = await getInsightsForAgency(
          data.agencyId ?? 0,
          startDate,
          endDate
        );

        if ((insights as any).error_message) {
          console.error("no data");
          toaster.push({
            message: "Currently there is no data for this agency",
            variant: "neutral",
            dismissAfter: 4000
          });
        } else {
          setLoaded(true);
          if (insights) {
            setFilteredReportingData(sortConnectionInsightsByTimestamp(insights));
          }
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve data",
          variant: "error",
          dismissAfter: 4000
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Box width="95%">
      <ConnectionHeader
        onGetReport={getReport}
        onExportData={exportTableData}
      />

      <ConnectionFilters
        loading={loading}
        startDate={startDate || ''}
        endDate={endDate || ''}
        preDateError={preDateError}
        dateError={dateError}
        servicesFilter={servicesFilter || []}
        servicesFilterValue={filterState.service}
        connectionTypesFilter={connectionTypesFilter}
        connectionTypesFilterValue={filterState.connectionType}
        languagesFilter={languagesFilter}
        languagesFilterValue={filterState.language}
        onDateChange={handleDateChange}
        onFilterChange={handleFilterChange}
        onApplyFilters={applyFilters}
      />

      <Scroller />

      <ConnectionChart
        reportData={reportData || {} as ConnectionInsightsReport}
        loaded={loaded}
        filteredReportingData={filteredReportingData}
      />

      <ConnectionTable
        filteredReportingData={filteredReportingData}
        loaded={loaded}
        convertUTCToLocal={convertUTCToLocal}
        userTimezone={userTimezone}
      />
    </Box>
  );
};