import { FC, useEffect, useState } from "react";
import { Heading } from "@twilio-paste/core/heading";
import { Text } from "@twilio-paste/core/text";
import { StepperStage2 } from "./StepperStage2";
import { Box } from "@twilio-paste/core/box";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { Flex } from "@twilio-paste/core/flex";
import { Button } from "@twilio-paste/core/button";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { Stack } from "@twilio-paste/core/stack";
import { Separator } from "@twilio-paste/core/separator";

import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Service, AgencyVideo } from "@ciptex/nfcc";

type LocationProps = {
  state: {
    selectedServiceIds: number[];
    channelConfigs: any[];
    channelConfigInstances: any[];
  };
};

export const ChannelSelection: FC = () => {
  const navigate = useNavigate();
  const { toaster } = useToasterContext();
  const [loaded, setLoaded] = useState<boolean>();
  const location = useLocation() as unknown as LocationProps;
  const [channelConfigs, setChannelConfigs] = useState<any[]>();
  const { appState } = useAppState();
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>();
  const [channelConfigInstances, setChannelConfigInstances] = useState<any[]>();
  const [agencyVideoEnabled, setAgencyVideoEnabled] = useState<boolean>();

  const { agencyId } = useParams();

  const { getService, getAgencyVideo } = useNFCCContext();

  useEffect(() => {
    (async () => {
      try {
        const agId: number = parseInt(agencyId ?? "0") || appState.agencyId;

        const agencyVideo: AgencyVideo = await getAgencyVideo(agId);

        let vidEnabled = false;

        if (agencyVideo.status === "Active") {
          setAgencyVideoEnabled(true);
          vidEnabled = true;
        } else {
          setAgencyVideoEnabled(false);
        }

        const { state } = location;
        setSelectedServiceIds(state.selectedServiceIds);
        const srvs: Service[] = [];
        const chs: any[] = [];

        for (const s of state.selectedServiceIds) {
          const data: Service = await getService(s);

          srvs.push(data);

          let phoneEnglishEnabled = false;
          let phoneSpanishEnabled = false;
          let onlineEnglishEnabled = false;
          let onlineSpanishEnabled = false;
          let videoEnglishEnabled = false;
          let videoSpanishEnabled = false;

          if (data.availableLanguages?.includes("English")) {
            if (data.availableConnectionTypes?.includes("Phone")) {
              phoneEnglishEnabled = true;
            }

            if (data.availableConnectionTypes?.includes("Online")) {
              onlineEnglishEnabled = true;
            }
            if (
              data.availableConnectionTypes?.includes("Video") &&
              vidEnabled
            ) {

              videoEnglishEnabled = true;
            }
          }

          if (data.availableLanguages?.includes("Spanish")) {
            if (data.availableConnectionTypes?.includes("Phone")) {
              phoneSpanishEnabled = true;
            }

            if (data.availableConnectionTypes?.includes("Online")) {
              onlineSpanishEnabled = true;
            }

            if (
              data.availableConnectionTypes?.includes("Video") &&
              vidEnabled
            ) {
              videoSpanishEnabled = true;
            }
          }


          chs.push({
            serviceId: data.serviceId,
            category: data.category,
            name: data.name,
            phoneEnglish: false,
            phoneSpanish: false,
            onlineEnglish: false,
            onlineSpanish: false,
            videoEnglish: false,
            videoSpanish: false,
            phoneEnglishEnabled: phoneEnglishEnabled,
            onlineEnglishEnabled: onlineEnglishEnabled,
            phoneSpanishEnabled: phoneSpanishEnabled,
            onlineSpanishEnabled: onlineSpanishEnabled,
            videoSpanishEnabled: videoSpanishEnabled,
            videoEnglishEnabled: videoEnglishEnabled,
          });
        }

        if (state.channelConfigInstances) {
          setChannelConfigInstances(state.channelConfigInstances);
        }

        if (state.channelConfigs) {
          for (const c in chs) {
            for (const s of state.channelConfigs) {
              if (chs[parseInt(c)].serviceId === s.serviceId) {
                chs.splice(parseInt(c), 1, s);
              }
            }
          }
          setChannelConfigs(state.channelConfigs);
        }

        setChannelConfigs(chs);

        setLoaded(true);
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve service data",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const onCheckboxChange = ({ target }: any, rowIndex: number) => {
    if (channelConfigs) {
      const newChannelConfigs = (channelConfigs ?? []).map((x) => x);

      let newCC = newChannelConfigs[rowIndex];

      if (target.id === rowIndex + "-phone-english") {
        newCC = { ...newCC, ["phoneEnglish"]: target.checked };
      } else if (target.id === rowIndex + "-phone-spanish") {
        newCC = { ...newCC, ["phoneSpanish"]: target.checked };
      } else if (target.id === rowIndex + "-online-english") {
        newCC = { ...newCC, ["onlineEnglish"]: target.checked };
      } else if (target.id === rowIndex + "-online-spanish") {
        newCC = { ...newCC, ["onlineSpanish"]: target.checked };
      } else if (target.id === rowIndex + "-video-english") {
        newCC = { ...newCC, ["videoEnglish"]: target.checked };
      } else if (target.id === rowIndex + "-video-spanish") {
        newCC = { ...newCC, ["videoSpanish"]: target.checked };
      }

      newChannelConfigs.splice(rowIndex, 1, newCC);
      setChannelConfigs(newChannelConfigs);
    }
  };

  const setAllChecked = (checked: boolean) => {
    if (channelConfigs) {
      const newChannelConfigs = [];

      for (const s of channelConfigs) {
        if (!checked) {
          newChannelConfigs.push({
            ...s,
            serviceId: s.serviceId,
            name: s.name,
            phoneEnglish: false,
            phoneSpanish: false,
            onlineEnglish: false,
            onlineSpanish: false,
            videoEnglish: false,
            videoSpanish: false,
          });
        } else {
          let phoneEnglish = false;
          let phoneSpanish = false;
          let onlineEnglish = false;
          let onlineSpanish = false;
          let videoEnglish = false;
          let videoSpanish = false;

          if (s.phoneEnglishEnabled) {
            phoneEnglish = true;
          }
          if (s.phoneSpanishEnabled) {
            phoneSpanish = true;
          }
          if (s.onlineEnglishEnabled) {
            onlineEnglish = true;
          }
          if (s.onlineSpanishEnabled) {
            onlineSpanish = true;
          }
          if (s.videoEnglishEnabled) {
            videoEnglish = true;
          }
          if (s.videoSpanishEnabled) {
            videoSpanish = true;
          }

          newChannelConfigs.push({
            ...s,
            serviceId: s.serviceId,
            name: s.name,
            phoneEnglish: phoneEnglish,
            phoneSpanish: phoneSpanish,
            onlineEnglish: onlineEnglish,
            onlineSpanish: onlineSpanish,
            videoEnglish: videoEnglish,
            videoSpanish: videoSpanish,
          });
        }
      }

      setChannelConfigs(newChannelConfigs);
    }
  };

  const setRowChecked = (checked: boolean, rowLabel: string) => {
    if (channelConfigs) {
      const newChannelConfigs = [];

      for (const s of channelConfigs) {
        if (rowLabel === "phone-english") {
          if (s.phoneEnglishEnabled) {
            newChannelConfigs.push({
              ...s,
              phoneEnglish: checked,
            });
          } else {
            newChannelConfigs.push({
              ...s,
              phoneEnglish: false,
            });
          }
        } else if (rowLabel === "phone-spanish") {
          if (s.phoneSpanishEnabled) {
            newChannelConfigs.push({
              ...s,
              phoneSpanish: checked,
            });
          } else
            newChannelConfigs.push({
              ...s,
              phoneSpanish: false,
            });
        } else if (rowLabel === "online-english") {
          if (s.onlineEnglishEnabled) {
            newChannelConfigs.push({
              ...s,
              onlineEnglish: checked,
            });
          } else {
            newChannelConfigs.push({
              ...s,
              onlineEnglish: false,
            });
          }
        } else if (rowLabel === "online-spanish") {
          if (s.onlineSpanishEnabled) {
            newChannelConfigs.push({
              ...s,
              onlineSpanish: checked,
            });
          } else {
            newChannelConfigs.push({
              ...s,
              onlineSpanish: false,
            });
          }
        } else if (rowLabel === "video-english") {
          if (s.videoEnglishEnabled) {
            newChannelConfigs.push({
              ...s,
              videoEnglish: checked,
            });
          } else {
            newChannelConfigs.push({
              ...s,
              videoEnglish: false,
            });
          }
        } else if (rowLabel === "video-spanish") {
          if (s.videoSpanishEnabled) {
            newChannelConfigs.push({
              ...s,
              videoSpanish: checked,
            });
          } else {
            newChannelConfigs.push({
              ...s,
              videoSpanish: false,
            });
          }
        }
      }

      setChannelConfigs(newChannelConfigs);
    }
  };

  function allChecked(): boolean {
    try {
      if (channelConfigs) {
        let checked = true;
        for (const s of channelConfigs) {
          if (
            (s.phoneEnglish || !s.phoneEnglishEnabled) &&
            (s.phoneSpanish || !s.phoneSpanishEnabled) &&
            (s.onlineEnglish || !s.onlineEnglishEnabled) &&
            (s.onlineSpanish || !s.onlineSpanishEnabled) &&
            (s.videoEnglish || !s.videoEnglishEnabled) &&
            (s.videoSpanish || !s.videoSpanishEnabled)
          ) {
            checked = true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  function noneChecked(): boolean {
    try {
      if (channelConfigs) {
        let checked = true;
        for (const s of channelConfigs) {
          if (
            !s.phoneEnglish &&
            !s.phoneSpanish &&
            !s.onlineEnglish &&
            !s.onlineSpanish &&
            !s.videoEnglish &&
            !s.videoSpanish
          ) {
            checked = true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  function allRowChecked(rowLabel: string): boolean {
    try {
      if (channelConfigs) {
        let checked = true;
        for (const s of channelConfigs) {
          if (
            ((rowLabel === "phone-english" &&
              (s.phoneEnglish || !s.phoneEnglishEnabled)) ||
              (rowLabel === "phone-spanish" &&
                (s.phoneSpanish || !s.phoneSpanishEnabled)) ||
              (rowLabel === "online-english" &&
                (s.onlineEnglish || !s.onlineEnglishEnabled)) ||
              (rowLabel === "online-spanish" &&
                (s.onlineSpanish || !s.onlineSpanishEnabled)) ||
              (rowLabel === "video-english" &&
                (s.videoEnglish || !s.videoEnglishEnabled)) ||
              (rowLabel === "video-spanish" &&
                (s.videoSpanish || !s.videoSpanishEnabled))) &&
            !s.phoneEnglishEnabled &&
            !s.phoneSpanishEnabled &&
            !s.onlineEnglishEnabled &&
            !s.onlineSpanishEnabled &&
            !s.videoEnglishEnabled &&
            !s.videoSpanishEnabled
          ) {
            checked = true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  function allNotChecked(): boolean {
    try {
      if (channelConfigs) {
        let notChecked = true;
        for (const s of channelConfigs) {
          if (
            !s.phoneEnglish &&
            !s.phoneSpanish &&
            !s.onlineEnglish &&
            !s.onlineSpanish &&
            !s.videoEnglish &&
            !s.videoSpanish
          ) {
            notChecked = true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  function allRowNotChecked(rowLabel: string): boolean {
    try {
      if (channelConfigs) {
        let notChecked = true;
        for (const s of channelConfigs) {
          if (
            (rowLabel === "phone-english" && !s.phoneEnglish) ||
            (rowLabel === "phone-spanish" && !s.phoneSpanish) ||
            (rowLabel === "online-english" && !s.onlineEnglish) ||
            (rowLabel === "online-spanish" && !s.onlineSpanish) ||
            (rowLabel === "video-english" && !s.videoEnglish) ||
            (rowLabel === "video-spanish" && !s.videoSpanish)
          ) {
            notChecked = true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  const getNextLink = () => {
    if (appState.role === "nfcc_admin") {
      const p = generatePath(
        "/agencies/:agencyId/start/selection/channel/setup",
        { agencyId: agencyId },
      );
      return p;
    } else {
      const p = generatePath("/start/selection/channel/setup");
      return p;
    }
  };

  const getPreviousLink = () => {
    if (appState.role === "nfcc_admin") {
      const p = generatePath("/agencies/:agencyId/start/selection/", {
        agencyId: agencyId,
      });
      return p;
    } else {
      const p = generatePath("/start/selection/");
      return p;
    }
  };

  return (
    <>
      <StepperStage2 />
      <CheckboxGroup key="parentgroup" name="servicescbgroup" legend="">
        <Flex marginY="space60">
          <Box marginY="space60">
            <Heading as="h1" variant="heading10">
              Channel Selection
            </Heading>

            {/* select all text and checkbox */}
            <Box display="inline-flex" alignItems="center" columnGap="space60">
              <Text as="p" fontSize="fontSize40" lineHeight="lineHeight40">
                Please select how you want to offer the following service(s).
              </Text>
              <Box width="150px">
                <Checkbox
                  key="select_all"
                  id="select_all"
                  checked={allChecked()}
                  indeterminate={!allChecked() && !allNotChecked()}
                  onChange={(e) => setAllChecked(e.target.checked)}
                >
                  Select all
                </Checkbox>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex>
          {loaded ? (
            <Table>
              <THead role="list">
                <Th role="listitem">Services</Th>
                <Th
                  role="listitem"
                  width="size20"
                  textAlign="right"
                  colSpan={1}
                >
                  Phone
                </Th>
                <Th />
                <Th role="listitem" scope="row">
                  <Separator orientation="vertical" />
                </Th>
                <Th
                  role="listitem"
                  width="size20"
                  textAlign="right"
                  colSpan={1}
                >
                  Online
                </Th>
                <Th />
                <Th role="listitem" scope="row">
                  <Separator orientation="vertical" />
                </Th>
                <Th
                  role="listitem"
                  width="size20"
                  textAlign="right"
                  colSpan={1}
                >
                  Video
                </Th>
                <Th />
              </THead>
              <TBody>
                <Tr>
                  <Td />
                  <Td>English</Td>
                  <Td>Spanish</Td>
                  <Td scope="row">
                    <Separator orientation="vertical" />
                  </Td>
                  <Td>English</Td>
                  <Td>Spanish</Td>
                  <Td scope="row">
                    <Separator orientation="vertical" />
                  </Td>
                  <Td>English</Td>
                  <Td>Spanish</Td>
                </Tr>
                <Tr>
                  <Th>Select all below</Th>
                  <Td>
                    <Checkbox
                      key="select_all_phone_english"
                      id="select_all_phone_english"
                      aria-label="Select all English for phone"
                      checked={allRowChecked("phone-english")}
                      indeterminate={
                        !allRowChecked("phone-english") &&
                        !allRowNotChecked("phone-english")
                      }
                      onChange={(e) =>
                        setRowChecked(e.target.checked, "phone-english")
                      }
                    >
                      {null!}
                    </Checkbox>
                  </Td>
                  <Td>
                    <Checkbox
                      key="select_all_phone_spanish"
                      id="select_all_phone_spanish"
                      aria-label="Select all Spanish for phone"
                      checked={allRowChecked("phone-spanish")}
                      indeterminate={
                        !allRowChecked("phone-spanish") &&
                        !allRowNotChecked("phone-spanish")
                      }
                      onChange={(e) =>
                        setRowChecked(e.target.checked, "phone-spanish")
                      }
                    >
                      {null!}
                    </Checkbox>
                  </Td>
                  <Td scope="row">
                    <Separator orientation="vertical" />
                  </Td>
                  <Td>
                    <Checkbox
                      key="select_all_online_english"
                      id="select_all_online_english"
                      aria-label="Select all English for online"
                      checked={allRowChecked("online-english")}
                      indeterminate={
                        !allRowChecked("online-english") &&
                        !allRowNotChecked("online-english")
                      }
                      onChange={(e) =>
                        setRowChecked(e.target.checked, "online-english")
                      }
                    >
                      {null!}
                    </Checkbox>
                  </Td>
                  <Td>
                    <Checkbox
                      key="select_all_online_spanish"
                      id="select_all_online_spanish"
                      aria-label="Select all Spanish for online"
                      checked={allRowChecked("online-spanish")}
                      indeterminate={
                        !allRowChecked("online-spanish") &&
                        !allRowNotChecked("online-spanish")
                      }
                      onChange={(e) =>
                        setRowChecked(e.target.checked, "online-spanish")
                      }
                    >
                      {null!}
                    </Checkbox>
                  </Td>
                  <Td scope="row">
                    <Separator orientation="vertical" />
                  </Td>
                  <Td>
                    <Checkbox
                      key="select_all_video_english"
                      id="select_all_video_english"
                      aria-label="Select all English for video"
                      checked={allRowChecked("video-english")}
                      indeterminate={
                        !allRowChecked("video-english") &&
                        !allRowNotChecked("video-english")
                      }
                      onChange={(e) =>
                        setRowChecked(e.target.checked, "video-english")
                      }
                    >
                      {null!}
                    </Checkbox>
                  </Td>
                  <Td>
                    <Checkbox
                      key="select_all_video_spanish"
                      id="select_all_video_spanish"
                      aria-label="Select all Spanish for video"
                      checked={allRowChecked("video-spanish")}
                      indeterminate={
                        !allRowChecked("video-spanish") &&
                        !allRowNotChecked("video-spanish")
                      }
                      onChange={(e) =>
                        setRowChecked(e.target.checked, "video-spanish")
                      }
                    >
                      {null!}
                    </Checkbox>
                  </Td>
                </Tr>
                {loaded &&
                  channelConfigs &&
                  channelConfigs.map((config: any, rowIndex: number) => (
                    <Tr key={rowIndex}>
                      <Td>{config.name}</Td>
                      <Td>
                        <Checkbox
                          key={rowIndex + "-phone-english"}
                          id={rowIndex + "-phone-english"}
                          checked={config.phoneEnglish}
                          name={rowIndex + "-phone-english"}
                          aria-label={
                            "Select English for phone for " + config.name
                          }
                          onClick={(e) => onCheckboxChange(e, rowIndex)}
                          disabled={!config.phoneEnglishEnabled}
                        >
                          {null!}
                        </Checkbox>
                      </Td>
                      <Td>
                        <Checkbox
                          key={rowIndex + "-phone-spanish"}
                          id={rowIndex + "-phone-spanish"}
                          checked={config.phoneSpanish}
                          name={rowIndex + "-phone-spanish"}
                          aria-label={
                            "Select Spanish for phone for " + config.name
                          }
                          onClick={(e) => onCheckboxChange(e, rowIndex)}
                          disabled={!config.phoneSpanishEnabled}
                        >
                          {null!}
                        </Checkbox>
                      </Td>
                      <Td scope="row">
                        <Separator orientation="vertical" />
                      </Td>
                      <Td>
                        <Checkbox
                          key={rowIndex + "-online-english"}
                          id={rowIndex + "-online-english"}
                          checked={config.onlineEnglish}
                          name={rowIndex + "-online-english"}
                          aria-label={
                            "Select English for online for " + config.name
                          }
                          onClick={(e) => onCheckboxChange(e, rowIndex)}
                          disabled={!config.onlineEnglishEnabled}
                        >
                          {null!}
                        </Checkbox>
                      </Td>
                      <Td>
                        <Checkbox
                          key={rowIndex + "-online-spanish"}
                          id={rowIndex + "-online-spanish"}
                          checked={config.onlineSpanish}
                          name={rowIndex + "-online-spanish"}
                          aria-label={
                            "Select Spanish for online for " + config.name
                          }
                          onClick={(e) => onCheckboxChange(e, rowIndex)}
                          disabled={!config.onlineSpanishEnabled}
                        >
                          {null!}
                        </Checkbox>
                      </Td>
                      <Td scope="row">
                        <Separator orientation="vertical" />
                      </Td>
                      <Td>
                        <Checkbox
                          key={rowIndex + "-video-english"}
                          id={rowIndex + "-video-english"}
                          checked={config.videoEnglish}
                          name={rowIndex + "-video-english"}
                          aria-label={
                            "Select English for video for " + config.name
                          }
                          onClick={(e) => onCheckboxChange(e, rowIndex)}
                          disabled={!config.videoEnglishEnabled}
                        >
                          {null!}
                        </Checkbox>
                      </Td>
                      <Td>
                        <Checkbox
                          key={rowIndex + "-video-spanish"}
                          id={rowIndex + "-video-spanish"}
                          checked={config.videoSpanish}
                          name={rowIndex + "-video-spanish"}
                          aria-label={
                            "Select Spanish for video for " + config.name
                          }
                          onClick={(e) => onCheckboxChange(e, rowIndex)}
                          disabled={!config.videoSpanishEnabled}
                        >
                          {null!}
                        </Checkbox>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>
          ) : (
            <Box width="100%">
              <Stack orientation="vertical" spacing="space60">
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </Stack>
            </Box>
          )}
        </Flex>
      </CheckboxGroup>
      <Box
        display="flex"
        justifyContent="space-between"
        position="relative"
        bottom="0"
        marginY="space60"
      >
        <Button variant="secondary">
          <Link
            style={{ color: "rgb(18, 28, 45)", textDecoration: "none" }}
            to={getPreviousLink()}
            state={{
              selectedServiceIds: selectedServiceIds,
              channelConfigs: channelConfigs,
              channelConfigInstances: channelConfigInstances,
            }}
          >
            Back
          </Link>
        </Button>
        {noneChecked() && (
          <Button variant="primary" disabled>
            Continue
          </Button>
        )}
        {!noneChecked() && (
          <Button variant="primary">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to={getNextLink()}
              state={{
                channelConfigs: channelConfigs,
                selectedServiceIds: selectedServiceIds,
                channelConfigInstances: channelConfigInstances,
              }}
            >
              Continue
            </Link>
          </Button>
        )}
      </Box>
    </>
  );
};
