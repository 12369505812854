
import { useAppState } from "../hooks/useAppState/useAppState";
import { useNFCCContext } from "../hooks/useNFCCContext/useNFCCContext";

export const getUserTimezone = () => {
	const { appState } = useAppState();
	const { listUsersEmailFilter, getUser } = useNFCCContext();

	const fetchUserTimezone = async (): Promise<any> => {
		try {
			const users = await listUsersEmailFilter(
				encodeURI(appState.email)
			);
            
			if (!users.length) {
				return "EST";
			}

			const user = await getUser(users[0].userId ?? 0);
			return user.timezone ?? "EST";
		} catch (error) {
			console.error('Error fetching user timezone:', error);
			return "EST";
		}
	};

	return { fetchUserTimezone };
};
