import { DateTime } from "luxon";
import { ConnectionInsights } from "@ciptex/nfcc";

export const sortConnectionInsightsByTimestamp = (data: ConnectionInsights): ConnectionInsights => {
  const sorted = [...data].sort((a, b) => {
    try {
      const dateAStr = a.endDateTime?.split(".")[0];
      const dateBStr = b.endDateTime?.split(".")[0];

      if (!dateAStr || !dateBStr) {
        console.error("Missing datetime:", { a: a.endDateTime, b: b.endDateTime });
        return 0;
      }

      const dateA = DateTime.fromFormat(dateAStr, "yyyy-MM-dd HH:mm:ss");
      const dateB = DateTime.fromFormat(dateBStr, "yyyy-MM-dd HH:mm:ss");

      if (!dateA.isValid || !dateB.isValid) {
        console.error("Invalid date found:", {
          dateA: dateAStr,
          dateB: dateBStr,
          validA: dateA.isValid,
          validB: dateB.isValid
        });
        return 0;
      }

      return dateB.toMillis() - dateA.toMillis();
    } catch (error) {
      console.error("Error parsing dates:", error);
      return 0;
    }
  });

  return sorted.map(item => {
    if (item.endDateTime) {
      const dateStr = item.endDateTime.split(".")[0];
      const date = DateTime.fromFormat(dateStr, "yyyy-MM-dd HH:mm:ss");
      if (date.isValid) {
        return {
          ...item,
          endDateTime: date.toFormat("MM/dd/yyyy HH:mm")
        };
      }
    }
    return item;
  });
};