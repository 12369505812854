import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { Bar } from "react-chartjs-2";
import { ConnectionInsightsReport } from "@ciptex/nfcc";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ConnectionChartProps {
  reportData: ConnectionInsightsReport;
  loaded: boolean;
  filteredReportingData: any[]; // Consider typing this more specifically
}

export const ConnectionChart: FC<ConnectionChartProps> = ({
  reportData,
  loaded,
  filteredReportingData,
}) => {
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category' as const,  // Explicitly type this axis
      },
      y: {
        type: 'linear' as const,    // Explicitly type this axis
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels: (reportData || { dates: [] }).dates,
    datasets: [
      {
        label: "Phone",
        data: (reportData || { phone: [] }).phone,
        backgroundColor: "#4b81c3"
      },
      {
        label: "Online",
        data: (reportData || { online: [] }).online,
        backgroundColor: "#150c39"
      },
      {
        label: "Video",
        data: ((reportData || { video: [] }) as any).video,
        backgroundColor: "#621F52"
      }
    ]
  };

  if (!reportData || Object.keys(reportData).length === 0 || !loaded || !filteredReportingData) {
    return null;
  }

  return (
    <Box display="flex" width="50%" alignSelf="center" alignItems="center">
      <Bar aria-label="insights-graph" options={options} data={data} />
    </Box>
  );
};